import React, { useRef, useState } from 'react';
import Slider from "react-slick";
// import { useMediaQuery } from 'react-responsive';
import { Button, Image } from 'react-bootstrap';
import data from '../components/data/slider';
import NextIcon from '../assets/images/right-arrow.svg';
import PrevIcon from '../assets/images/left-arrow.svg';

import '../assets/styles/slider.scss'

const SliderContent = () => {
  const [sliding, setSliding] = useState(false);
  const [currentSlider, setCurrentSlider] = useState(0);
  const fundRef = useRef({});

  const next = () => {
    setSliding(true)
    fundRef.current.slickNext();
    setCurrentSlider(currentSlider + 1)
  };

  const prev = () => {
    setSliding(true)
    fundRef.current.slickPrev();
    setCurrentSlider(currentSlider - 1)
  };

  const settings = {
    infinite: true,
    speed: 500,
    variableWidth: true,
    slidesToScroll: 1,
    setCurrentSlider: (current) => setCurrentSlider(current),
    afterChange: () => setSliding(false),
  };
  console.log(data);

  return (
    <div className="testimonial-slider-container">
      <div className="slider-content">
        <Slider ref={fundRef} {...settings}>
          { data && data.map((item) => (
             <div className='container' key={item.id}>
               <div className='content-all'>
                  <div className='desc'>
                    {item.desc}
                  </div>
                  <div className='below-desc'>
                  <Image src={item.logo} className='img'/>
                  <div className='img-content'>
                    <div className='name'>{item.name}</div>
                    <div className='position'>{item.position}</div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <div className="slider-button d-flex justify-content-end">
        <Button
          className={`next-icon ${currentSlider === 0 ? 'disable' : ''}`}
          disabled={currentSlider === 0 || sliding}
          onClick={() => prev()}
        >
          <Image src={PrevIcon} />
        </Button>
        <Button
          className={`next-icon ${currentSlider === 7 ? 'disable' : ''}`}
          onClick={() => next()}
          disabled={currentSlider === 3 || sliding}
        >
          <Image src={NextIcon} />
        </Button>
      </div>
    </div>
  );
};

export default SliderContent;
