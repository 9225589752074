import React, { useEffect, useState } from "react";
import { Container, Image, Navbar, Nav, Form, Button } from 'react-bootstrap';
import { Link  } from "react-router-dom";
import logo from "../../../assets/images/dark.svg";
import ToggleMenu from "../../../components/ToggleMenu";
import hamburgerBlack from "../../../assets/images/hamburger-black.svg";


const Header = () => {
  const [toggling, setToggling] = useState(false);

  const handleToogleMenu = () => {
    setToggling(!toggling)
  }

  useEffect(() => {
    if (toggling) { 
      document.body.style = "overflow-y: hidden";
    } else {
      document.body.style = "overflow-y: auto";
    }
  }, [toggling]);

  return (
    <div className="w-100 header-wrapper">
      <Container className="Layout-container">
        <div className="header-container w-100">
          <Navbar collapseOnSelect className="header" expand="lg">
            <Navbar.Brand className="header-logo">
              <Image className="header-logo-image" src={logo} width={145} height={40} />
            </Navbar.Brand>
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav as={Link} className="header-tabs" to="/">
                Home
              </Nav>
              <Nav className="header-tabs">
                Mission
              </Nav>
              <Nav className="header-tabs">
                Pricing
              </Nav>
            </Navbar.Collapse>
            <Navbar>
              <Form inline>
                <div className="header-buttons">
                  <a href={process.env.REACT_APP_LOGIN_URL} className='header-button-login header-button-login__black'>Log In</a>
                  <a href={process.env.REACT_APP_SIGNUP_URL} className="header-button-join">Join Now</a>
                  {!toggling &&
                  <Button 
                    className='header-nav-toggler'
                    onClick={handleToogleMenu}>
                    <Image src={hamburgerBlack} />
                  </Button>}
              </div>
              </Form>
            </Navbar>
          </Navbar>
        <ToggleMenu handleToogleMenu={handleToogleMenu} toggling={toggling}/>
        </div>
      </Container>
    </div>
  )
};

export default Header;
