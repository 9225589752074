import React from "react";
import { Container, Image, Row, Col } from 'react-bootstrap';
import StartupImg from "../../../assets/images/investors/startup.png";
import StartupDecorTop from "../../../assets/images/investors/startup_top.svg";
import StartupDecorBottom from "../../../assets/images/investors/startup_down.svg";
import DecorPaint from "../../../assets/images/investors/signup_paint.svg";
import Decor from "../../../assets/images/investors/startup_decor.svg";

const Startup = () => {
  return (
    <div className="w-100 startup-wrapper">
      <Container>
        <Image src={StartupDecorTop} className="startup-decor-top" />
        <Image src={StartupDecorBottom} className="startup-decor-bottom" />
        <Image src={Decor} className="startup-decor" />
        <Row>
          <Col md={6} className="content-left">
            <Image src={DecorPaint} className="decor-paint" />
            <div className="content-block">
              <div className="title">
                <p>Find New</p>
                Investment Opportunities
              </div>
              <div className="description">Access deal flow that fits within your investment thesis. Founders will choose to send the information and documents that they built in Cobble, making it easier for you to analyze incoming startups.</div>
            </div>
          </Col>
          <Col md={6} className="content-right">
            <Image src={StartupImg} className="startup-img" />
          </Col>
        </Row>
      </Container>
    </div>
  )
};

export default Startup;
