import React from "react";
import { Container, Image, Row, Col } from 'react-bootstrap';
import Notebook from "../../../assets/images/investors/notebook_header.png";
// import Notebook from "../../../assets/images/notebook_3X.jpg";
import DecorRight from "../../../assets/images/investors/line_header.svg";
import DecorPaint from "../../../assets/images/investors/big_paint.svg";
import DecorDot from "../../../assets/images/investors/dot_paint.svg";

const Signup = () => {
  return (
    <Container className="signup-wrapper">
      <Row className="first-row">
        <Col md={6} className="content-left">
          <div className="content-block">
            <div className="title">Investor Deal Flow Tool</div>
            <div className="description">Use Cobble to find and manage startup deal flow opportunities. Our software is made for venture capitalists, angel investors, and analysts looking for the next addition to their portfolio.</div>
            <a href={process.env.REACT_APP_SIGNUP_URL} className="signup-button">Sign Up</a>
          </div>
          <Image src={DecorPaint} className="decor-paint" />
          <Image src={DecorDot} className="decor-dot" />
        </Col>
        <Col md={6} className="content-right">
          <Image src={Notebook} className="notebook-img" />
          <Image src={DecorRight} className="notebook-img-decor" />
        </Col>
      </Row>
    </Container>
  )
};

export default Signup;
