/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Link, useHistory  } from "react-router-dom";
import { Image, Button, Navbar, Nav, Container } from 'react-bootstrap';
import { Link as ScrollLink } from 'react-scroll'
import logo from "../assets/images/white.svg";
import logoBlack from "../assets/images/dark_logo.svg";
import collapse from "../assets/images/collapse.png";
import arrowRight from "../assets/images/arrow-right.png";
import facebook from "../assets/images/facebook.png";
import twitter from "../assets/images/twitter.png";
import instagram from "../assets/images/instagram.png";
import hamburger from "../assets/images/hamburger.svg";
import letStartup from "../assets/images/let-start-up.svg";
import collapse_black from "../assets/images/mobile_black.svg";
import PropTypes from 'prop-types';

const Header = ({ hideMenu }) => {
  const [toggling, setToggling] = useState(false);

  const handleToogleMenu = () => {
    setToggling(!toggling)
  }

  const history = useHistory();

  const [activeLink, setActiveLink] = useState('/');
  const [height, setHeight] = useState('850px')

  useEffect(() => {
    if (window.location.protocol !== "https:") {
      // window.location.replace("https://www.gocobble.com");
    }
  }, [])

  useEffect(() => {
    if (toggling) { 
      document.body.style = "overflow-y: hidden";
    } else {
      document.body.style = "overflow-y: auto";
    }
  }, [toggling]);

  useEffect(() => {
    if (history?.location.pathname) {
      setActiveLink(history?.location.pathname);
    }
    if(history?.location.pathname !== '/') {
      setHeight('auto');
    } else {
      setHeight('850');
    }
  },[history])

  return (
    <header className={`header header-home header-${activeLink.replace('/', '')}`}>
      <Container className="Layout-container">
        <Navbar collapseOnSelect className="header" expand="lg">
          <Navbar.Brand className="header-logo">
            <Image className="header-logo-image" src={activeLink != '/' ? logoBlack : logo} width={183.5} height={38} />
          </Navbar.Brand>
          {
            !hideMenu && (
              <>
                  <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav as={Link} className="header-tabs" to="/">
                      Home
                    </Nav>
                    {activeLink === '/' && (
                      <>
                        <ScrollLink className="header-tabs navbar-nav" to="mission" spy={true} smooth={true} duration={500} >
                          Mission
                        </ScrollLink>
                        <ScrollLink className="header-tabs navbar-nav" to="pricing" spy={true} smooth={true} duration={500} >
                          Pricing
                        </ScrollLink>
                      </>
                    )}
                    <Nav as={Link} className={`header-tabs  ${activeLink === '/partners' ? 'active' : ' '}`} to="/partners">
                      Partners
                    </Nav>
                    <Nav as={Link} className="header-tabs" to="/investors">
                      For Investors
                    </Nav>
                  </Navbar.Collapse>
                  <div className="header-buttons">
                    <a href={process.env.REACT_APP_LOGIN_URL} className='header-button-login'>Log In</a>
                    <a href={process.env.REACT_APP_SIGNUP_URL} className="header-button-join">Join Now</a>
                    {!toggling &&
                      <Button 
                        className={`${['/', '/partners'].includes(activeLink) ? 'header-nav-toggler' : 'header-nav-toggler_black'}`} 
                        onClick={handleToogleMenu}>
                        <Image src={activeLink === '/' ? hamburger : collapse_black} />
                      </Button>}
                  </div>
                <ToggleMenu handleToogleMenu={handleToogleMenu} toggling={toggling}/>
              </>
            )
          }
        </Navbar>
      </Container>
    </header>
  )
};

const ToggleMenu = ({toggling, handleToogleMenu}) => {
  if(!toggling) return null;

  return (
    <div className="menu-collapse">
      <div className="menu-collapse-header">
        <div>
          <Image className="header-logo-image" src={logo} height={38} />
        </div>
        <Button className="header-nav-toggler" onClick={handleToogleMenu}>
          <Image src={collapse} width={21} height={21} />
        </Button>
      </div>
      <div className="menu-collapse-body">
        <a href={process.env.REACT_APP_SIGNUP_URL} className="header-button-join">Join Now</a>
        <a href={process.env.REACT_APP_LOGIN_URL} className='header-button-login'>Log In</a>
      </div>
      <div className="menu-collapse-footer">
        <div className="menu-callapse-socials">
          <Image src={facebook} width={12} height={26} />
          <Image src={twitter} width={32} height={26} />
          <Image src={instagram} width={26} height={26} />
        </div>
        <div className="menu-callapse-copyright">
          {"© Cobble 2022. All Rights Reserved."}
        </div>
      </div>
    </div>
  )
}

ToggleMenu.propTypes = {
  handleToogleMenu: PropTypes.func,
  toggling: PropTypes.bool
}

export default Header;
