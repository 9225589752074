/* eslint-disable no-unused-vars */
import React, {useState} from 'react';
import { Button, Image, Form, Carousel, Tab, Nav, Container, Row, Col } from 'react-bootstrap';
import { Element, Link as ScrollLink  } from 'react-scroll'
import MetaTags from 'react-meta-tags';

import homeBusinessSlider1 from '../../assets/images/tab1.png';
import homeBusinessSlider2 from '../../assets/images/tab2.png';
import homeBusinessSlider3 from '../../assets/images/tab3.png';
import homeBusinessSlider4 from '../../assets/images/tab4.png';
import homeBusinessSlider5 from '../../assets/images/tab5.png';
import founderImage from '../../assets/images/Founder.png';
import founderSpImage from '../../assets/images/founder_sp.png';
import deco1 from '../../assets/images/graphic.svg';
import deco2 from '../../assets/images/graphic-2.svg';
import decoRight from '../../assets/images/Notebook.png';
import BackGround from '../../assets/images/BG.svg';
import arrowArange from '../../assets/images/arrow-orange.svg';
import arrowYellow from '../../assets/images/arrow-yellow.svg';
import arrowGray from '../../assets/images/arrow-gray.svg';
import forFounderImage from '../../assets/images/bottom.png';
import forInvesterImage from '../../assets/images/for-investor.png';
import Newsletter from '../../assets/images/Newsletter1.png';
import Podcas from '../../assets/images/Podcast.png';
import Newspaper from '../../assets/images/Newsletter.png';
import Shadow from '../../assets/images/shadow.svg';
import ShadowTop from '../../assets/images/decor1.svg';
import VectorBot from '../../assets/images/Vector-bot.svg';
import FounderDecor from '../../assets/images/FounderDecor.svg';
import TheWhy from '../../assets/images/TheWhy.svg';
import WhyDecor from '../../assets/images/WhyDecor.svg';
import NewDecor from '../../assets/images/NewDecor.svg';
import NewRight from '../../assets/images/NewRight.svg';
import Slide1 from '../../assets/images/slide1.svg';
import Slide1Decor from '../../assets/images/slide1-decor.svg';
import Slide1DecorBot from '../../assets/images/deco-slide.svg';
import BlogImg from '../../assets/images/BlogImg.svg';
import BlocVector from '../../assets/images/BlocVector.svg';
import Thebook from '../../assets/images/Thebook.svg';
import Bookvector from '../../assets/images/Bookvector.svg';
import BookLeft from '../../assets/images/BookLeft.svg';
import PosdcastLeft from '../../assets/images/PosdcastLeft.svg';
import FinalTop from '../../assets/images/FinalTop.svg';
import GetInon from '../../assets/images/GetInon.svg';
import FinalDecor from '../../assets/images/FinalDecor.svg';
import VectorFinal from '../../assets/images/VectorFinal.svg';
import ResourceDecord from '../../assets/images/ResourceDecord.svg';
import ActionLine from '../../assets/images/ActionLine.svg';
import Chats from '../../assets/images/Chats.svg';
import Investment from '../../assets/images/Investment.svg';
import Network from '../../assets/images/Network.svg';
import Guides from '../../assets/images/Guides.svg';
import Book from '../../assets/images/Book.svg';
import Polygon from '../../assets/images/Polygon.svg';
import Layout from '../../components/Layout';



import './Home.scss';
const Home = () => {
  
  const [isYearlyPlan, setYearlyPlan] = useState(false);
  const changingState = (e) => {
    if (e.target.checked) {
      setYearlyPlan(true);
    } else {
      setYearlyPlan(false);
    }
  };
  console.log(isYearlyPlan);
  return (
    <>
      <MetaTags>
        <meta name="robots" content="noindex, nofollow" />
      </MetaTags>
      <Layout>
        <div className="home">
          <section className="section-introduction">
            <div className="introduction-inner">
              <Container>
                <div className="introduction-content">
                  <Row className="content-wrapper">
                    <Col xl="5" lg="6" sm="12">
                      <div className="introduction-panel">
                        <div className="introduction-slogan">
                          The async accelerator for founders
                        </div>
                        <div className="introduction-title">
                          <h1>Meet Cobble</h1>
                          <div className="introduction-title-banner"></div>
                        </div>
                        <h2 className="introduction-description">
                          Use Cobble to build your business, no matter what stage you’re in. Our founder-first app makes starting a company more accessible with business planning workflows for fundraising or bootstrapping.
                        </h2>
                        <div className="introduction-buttons">
                          <a href={process.env.REACT_APP_SIGNUP_URL} className="introduction-btn introduction-try-button">Try it Free!</a>
                          <ScrollLink className="introduction-btn introduction-pricing-button" to="pricing" spy={true} smooth={true} duration={500} >
                            See Pricing
                          </ScrollLink>
                        </div>
                      </div>
                    </Col>
                    <Col xl="7" lg="6" sm="12">
                      <div className="introduction-image">
                        <Image src={decoRight} />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Container>
            </div>
            
            {/* <div className="mb-home-introduction-panel">
              <div className="home-introduction-slogan">
                {"You Made It!"}
              </div>
              <div className="home-introduction-title">
                {"Let's Start Up is a community and tool-kit for scrappy, overlooked founders. We're glad you're here."}
              </div>
              <div className="home-introduction-buttons">
                <Button className="home-introduction-join-button">Join Now</Button>
              </div>
            </div> */}
          </section>
          
          <Element className="home-business-plan-container" name="mission">
            <div className="business-plan-header width-auto">
              <Container>
                <div className="business-plan-title">
                  <p className="title">Build your <span>Business</span></p>
                </div>
                <div className="business-plan-desc">
                  <p>{"As former entrepreneurs ourselves, we’ve been in the trenches and know what it takes to start a business. Cobble guides you through the most important steps of building a company with workflows and tools so that you can better plan and manage your startup."}</p>
                  {/* <p>{"most important steps with easy-to-follow workflows so that you can better plan and manage your startup."}</p> */}
                </div>
              </Container>
            </div>
            <Container className="business-plan-content width-auto">
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <div className="business-plan-side-items">
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="first">
                        <h4 className="title">Startup Starter</h4>
                        <div className="content">
                          <p>Ready to bring your business idea to life? The Startup Starter guides you through the first steps of building your new company. </p>
                          <ul>
                            <li>Business Model Canvas</li>
                            <li>Elevator Pitch</li>
                            <li>Positioning Statement </li>
                            <li>Revenue Calculator </li>
                            <li>Exportable One Pager</li>
                            <li>Notes & Document Storage</li>
                          </ul>
                          <div className="image">
                            <Image src={homeBusinessSlider1} width={'100%'} />
                          </div>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">
                        <h4 className="title">Business Plan Builder</h4>
                        <div className="content">
                          <p>Create a business plan for your startup without the guesswork. The Business Plan Builder guides you through filling out your startup&apos;s business plan. </p>
                          <ul>
                            <li>Write an executive summary</li>
                            <li>Create your company overview</li>
                            <li>Develop products and services</li>
                            <li>Analyze the market</li>
                            <li>Plan financials, marketing, and sales</li>
                          </ul>
                          <div className="image">
                            <Image src={homeBusinessSlider2} width={'100%'} />
                          </div>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">
                        <h4 className="title">Marketing Plan Maker</h4>
                        <div className="content">
                          <p>From product launch to long-term marketing strategy, we help you create a plan to increase brand awareness and land new customers.</p>
                          <ul>
                            <li>Define the target market</li>
                            <li>SWOT analysis</li>
                            <li>Choose marketing channels</li>
                            <li>Plan a year of marketing strategy</li>
                          </ul>
                          <div className="image">
                            <Image src={homeBusinessSlider3} width={'100%'} />
                          </div>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="four">
                        <h4 className="title">Product Roadmap</h4>
                        <div className="content">
                          <p>Create an agile product roadmap using our builder. Prioritize what your customers want and give your team direction.</p>
                          <ul>
                            <li>Use timeline and kanban views</li>
                            <li>Prioritize tasks</li>
                            <li>Set impact and effort levels</li>
                            <li>Plan product and development sprints</li>
                          </ul>
                          <div className="image">
                            <Image src={homeBusinessSlider4} width={'100%'} />
                          </div>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="five">
                        <h4 className="title">Founder’s Library</h4>
                        <div className="content">
                          <p>Explore our collection of entrepreneur resources and how-to&apos;s ranging on all topics ranging from fundraising to marketing and beyond.</p>
                          <ul>
                            <li>Read entrepreneur articles</li>
                            <li>Download guides and templates</li>
                            <li>Watch videos and listen to podcasts</li>
                          </ul>
                          <div className="image">
                            <Image src={homeBusinessSlider5} width={'100%'} />
                          </div>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                
                <div className="business-plan-slider">
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <Image src={homeBusinessSlider1} width={904} height={707} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <Image src={homeBusinessSlider2} width={904} height={707} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <Image src={homeBusinessSlider3} width={904} height={707} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="four">
                      <Image src={homeBusinessSlider4} width={904} height={707} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="five">
                      <Image src={homeBusinessSlider5} width={904} height={707} />
                    </Tab.Pane>
                  </Tab.Content>
                </div>
              </Tab.Container>
            </Container>
            <div className="business-plan-image"></div>
          </Element>
          <Container className="home-founder-container width-auto">
            <div className="home-founder-image">
              <Image src={founderImage} width={454} height={697} />
            </div>
            <div className="home-founder-description">
              <p className="tag">the why</p>
              <p className="title">Our fearless leader,</p>
              <p className="title title-line"> <span className="text">Janine Sickmeyer</span> <span className="line"></span></p> <br/>
              <p className="description">Janine is a tech founder turned investor. But her success didn&apos;t happen overnight. She pitched investors from coast to coast and after hearing almost 100 no&apos;s, she decided to start her business without any outside funding.</p>
              <div className="second-paragraph">
                <div className="image">
                  <Image src={founderSpImage} />
                </div>
                <div>
                  <p className="description">Janine bootstrapped her company to revenue and ultimately, acquisition within six years. Since then, Janine founded her venture capital firm called <a style={{color: '#212529'}} href="https://www.overlookedventures.com/">Overlooked Ventures</a> where she supports founders with capital and helps them to assess business models, solidify marketing plans, review pitch decks, and more.</p>
                  <p className="description">Cobble was built with the purpose to take all of Janine&apos;s research, experience, and startup resources, and apply them into an all-in-one app to help founders get further, faster.</p>
                </div>
              </div>
            </div>
          </Container>
          
          <Element className="home-pricing-container container width-auto" name="pricing">
            <div className="home-pricing-header">
              <p className="home-pricing-title">Pricing</p>
              <p className="home-pricing-desc">{"Flexible and affordable pricing tiers for scrappy founders."}</p>
              <div className="home-pricing-plans">
                <div className="home-pricing-toggle-section">
                  <span className="monthly-name">Monthly Plan </span>
                  <div className="form-check form-switch d-inline-block">
                    <input type="checkbox" className="form-check-input" id="site_state" checked={isYearlyPlan} onChange={changingState} style={{cursor: 'pointer'}} />
                  </div>
                </div>
                <div className="yearly-plan">
                  Yearly Plan <span className="off-section">15% OFF</span>
                </div>
              </div>
            </div>
            <div className="home-pricing-panel">
              <div className="home-pricing-item">
                <div className="pricing-item">
                  <p>PLAN</p>
                  <span>start free</span>
                  <ul>
                    <li>Templates</li>
                    <li>Founder’s Library</li>
                    <li>Personal CRM</li>
                    <li>Startup Starter</li>
                    <li className="disable">Revenue Calculator</li>
                    <li className="disable">Integrations</li>
                    <li className="disable">Business Plan Builder</li>
                    <li className="disable">Marketing Plan Maker</li>
                    <li className="disable">Product Roadmapper</li>
                    <li className="disable">Pitch Deck Creator</li>
                    <li className="disable">Investor Connector</li>
                  </ul>
                  <div className="price"><span>FREE</span></div>
                </div>
                <a href={process.env.REACT_APP_SIGNUP_URL} className="btn btn-primary home-pricing-button">
                  Get Started
                  <Image src={arrowArange} />
                </a>
              </div>
              <div className="home-pricing-item">
                <div className="pricing-item">
                  <p>BUILD</p>
                  <span>BUSINESS PLAN</span>
                  <ul>
                    <li>Templates</li>
                    <li>Founder’s Library</li>
                    <li>Personal CRM</li>
                    <li>Startup Starter</li>
                    <li>Revenue Calculator</li>
                    <li>Integrations</li>
                    <li>Business Plan Builder</li>
                    <li className="disable">Marketing Plan Maker</li>
                    <li className="disable">Product Roadmapper</li>
                    <li className="disable">Pitch Deck Creator</li>
                    <li className="disable">Investor Connector</li>
                  </ul>
                  {isYearlyPlan ? 
                  ( <div className="price">
                      <span>$255</span>/yearly
                    </div>)
                     : 
                  (<div className="price">
                      <span>$25</span>/month
                    </div>) }
                </div>
                <a href={process.env.REACT_APP_SIGNUP_URL} className="btn btn-primary home-pricing-button">
                  Get Started
                  <Image src={arrowYellow} />
                </a>
              </div>
              <div className="home-pricing-item">
              <div className="pricing-item">
                  <p>GROW</p>
                  <span>MARKETING & MORE</span>
                  <ul>
                    <li>Templates</li>
                    <li>Founder’s Library</li>
                    <li>Personal CRM</li>
                    <li>Startup Starter</li>
                    <li>Revenue Calculator</li>
                    <li>Integrations</li>
                    <li>Business Plan Builder</li>
                    <li>Marketing Plan Maker</li>
                    <li>Product Roadmapper</li>
                    <li>Pitch Deck Creator</li>
                    <li>Investor Connector</li>
                  </ul>
                  {isYearlyPlan ? 
                  ( <div className="price">
                      <span>$510</span>/yearly
                    </div>)
                     : 
                  (<div className="price">
                      <span>$50</span>/month
                    </div>) }
                </div>
                <a href={process.env.REACT_APP_SIGNUP_URL} className="btn btn-primary home-pricing-button">
                  Get Started
                  <Image src={arrowGray} />
                </a>
              </div>
            </div>
          </Element>
          <Container className="home-services-container width-auto">
            <div className="home-services-header">
              <p className="home-services-title"> <span>Startup tools</span> & access</p>
              <p className="home-services-title">to funding</p>
            </div>
            <div className="home-services-content">
              <div className="home-services-item for-founder">
                <div className="content-services ">
                  <p className="title">For Founders </p>
                  <p className="detail">
                    Cobble is a web app for entrepreneurs to start and build their business from day one or wherever they are in their startup journey. We help you cobble together your business model, marketing plan, user acquisition strategy, and product roadmap. You can also access a pool of interested investors and send your startup for funding consideration.
                  </p>
                </div>
                <div className="content-image">
                  <Image src={forFounderImage} width={384} height={234} />
                </div>
              </div>
              <div className="home-services-item for-invester">
                <div className="content-image">
                  <Image src={forInvesterImage} width={439} height={234} />
                </div>
                <div className="content-services ">
                  <p className="title">For Investors </p>
                  <p className="detail">
                    Cobble offers investors a portal where they can accept dealflow or politely pass while also managing relationships with founders through the personal CRM. When you sign up as an investor to receive deal-flow in a specific market, you can accept, review, make notes, and track the progress of the startup.
                  </p>
                </div>
              </div>
            </div>
            <div className="home-services-double">
              <div className="services-item">
                <div className="content-services ">
                  <p className="title">Total Transparency</p>
                  <p className="detail">
                    Cobble provides a transparent view on both sides of the startup process. We give investors an easy way to access and evaluate dealflow, while founders have a secure platform where they can plan their business with guided instructions.
                  </p>
                </div>
                <div className="content-image">
                  <Image src={Newsletter} className="newsletter"/>
                </div>
              </div>
              <div className="services-item">
                <div className="content-services ">
                  <p className="title">Cobble Removes Bias</p>
                  <p className="detail">
                    Every startup should have a fair shot at funding no matter their pedigree, connections, net worth, or background. Cobble helps level the playing field by giving founders the resources they need to build their startup idea and find access to capital without bias.
                  </p>
                </div>
                <div className="content-image">
                  <Image src={Podcas}/>
                </div>
              </div>
            </div>

          </Container>
          <div className="home-final-container">
            <Container className="home-final-content width-auto">
              <div className="home-content">
                <span>{"Let's Start building"}</span>
                <p>Get in on</p>
                <p>the Action</p>
              </div>
              <a href={process.env.REACT_APP_SIGNUP_URL} className="home-final-content-button">Try it Free!</a>
            </Container>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Home;
