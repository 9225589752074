import devon from '../../assets/images/devon.png'
import theresa from '../../assets/images/theresa.png';

const Data = [
  {
    id: 1,
    logo: devon,
    name: 'Devon Lane',
    position: 'Founder of Coffee Junktion',
    desc: 'Ever since we partnered with Cobble we have been able to scale our business needs dramatically. Facilisis imperdiet diam viverra vestibulum, semper. In et dictum suspendisse consectetur morbi sem. In amet, proin tristique pulvinar. Mi tortor commodo, elementum risus, dignissim habitant turpis.',
  },
  {
    id: 2,
    logo: theresa,
    position: 'CEO of Lorem Ipsum',
    name: 'Rhian Beutler',
    desc: 'Ever since we partnered with Cobble we have been able to scale our business needs dramatically. Facilisis imperdiet diam viverra vestibulum, semper. In et dictum suspendisse consectetur morbi sem. In amet, proin tristique pulvinar. Mi tortor commodo, elementum risus, dignissim habitant turpis.',
  }
  ]

export default Data;
