import React from "react";
import {
  Switch,
  Route,
  BrowserRouter,
} from "react-router-dom";

import Home from './pages/home/Home';
import Investor from './pages/investors';
import Terms from './pages/termsOfUse/Term';
import TermOfService from "./pages/termsOfUse/TermOfService";
import './App.scss';
import './assets/styles/app.scss';
import Policy from "./pages/termsOfUse/Policy";
import Partner from "./pages/partners/Partner";

const App = () => {  
  return (
    <div className="App" style={{ position: 'relative' }}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/investors">
            <Investor />
          </Route>
          <Route exact path="/terms-of-use">
            <Terms />
          </Route>
          <Route exact path="/terms-of-service">
            <TermOfService />
          </Route>
          <Route exact path="/privacy-policy">
            <Policy />
          </Route>
          <Route exact path="/partners">
            <Partner />
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
