/* eslint-disable react/prop-types */
import React from "react";
import { Image, Container, Row, Col } from 'react-bootstrap';
import logo from "../assets/images/white.svg";
import footerDeco from "../assets/images/footer-deco.png";
import FB from "../assets/images/FB.svg";
import TW from "../assets/images/TW.svg";
import INS from "../assets/images/INS.svg";

const Footer = ({ hideDeco }) => {
  return (
    <footer className="footer-container footer-home">
      {
        !hideDeco && (
          <div className="footer-deco">
            <Image src={footerDeco} width={300} height={300}/>
          </div>
        )
      }
      <Container>
        <div className="footer-main-content">
          <div className="footer-left-container">
            <Image className="header-logo-image" src={logo}/>
            <div className="footer-vertical-divide"></div>
            <div className="footer-left-social">
              <a href="https://www.facebook.com/GoCobble" target="_blank" rel="noreferrer"> <Image className="social" src={FB} /> </a>
              <a href="https://www.twitter.com/GoCobble" target="_blank" rel="noreferrer"> <Image className="social" src={TW} /> </a>
              <a href="https://www.instagram.com/GoCobble" target="_blank" rel="noreferrer"> <Image className="social" src={INS} /> </a>
            </div>
          </div>
          <div className="footer-right-container">
            {/* <div className="footer-left-subscribe">
              <p className="footer-left-subscribe-title">Sign up to our <b>Newsletter</b> !</p>
              <div className="footer-left-subscribe-group">
                <FormControl className="footer-left-subscribe-input" placeholder="Enter email" />
                <Button className="footer-left-subscribe-button">Sign Up</Button>
              </div>
            </div> */}
          </div>
        </div>
        <div className="footer-copyright px-0">
          <Row className="box-bottom mx-0">
            <Col md="4" className="policy-box px-0">
              <a
                href="https://www.gocobble.com/terms-of-use"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Use
              </a>
              <a
                href="https://www.gocobble.com/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </Col>
            <Col md="4" className="px-0">
              <div className="all-rights-reserved">
                © Cobble {new Date().getFullYear()}. All Rights Reserved.
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </footer>
  )
};

export default Footer;
