import React from 'react';
import { Row, Col, Image, Container } from 'react-bootstrap';
import Layout from './components/Layout';
import TermBg from '../../assets/images/terms-header.png';
import TermBgDecor from '../../assets/images/term-header-bg.svg';
import ThunderIcon from '../../assets/images/thunder.svg';
import DotsIcon from '../../assets/images/dots.svg';
import DotsOrangeIcon from '../../assets/images/dots-orange.svg';
import TriangleIcon from '../../assets/images/triangle-blue.svg';
import LinesIcon from '../../assets/images/lines.svg';
import FooterIcon from '../../assets/images/terms-bottom-decor.png';
import LineTop from '../../assets/images/line-top.svg';
import LineBottom from '../../assets/images/line-bottom.svg';

const TermOfService = () => {
    return (
      <Layout>
        <Container className="term-wrapper">
          <Row>
            <Col sm="6" className="header-left">
              <h1>Our Terms & <br /><span>Conditions</span></h1>
            </Col>
            <Col sm="6" className="header-right">
              <Image width={486} height={345} className="bg" src={TermBg} />
              <Image width={300} height={295} className="decor" src={TermBgDecor} />
            </Col>
          </Row>
          <Row>
            <Col sn="12" className="content position-relative">
              <Image src={ThunderIcon} className="decor-thunder" />
              <Image src={DotsIcon} className="decor-dots" />
              <Image src={DotsOrangeIcon} className="decor-dots-orange" />
              <Image src={TriangleIcon} className="decor-triangle" />
              <Image src={LinesIcon} className="decor-lines" />
              <Image src={FooterIcon} className="decor-footer" />
              <Image src={LineTop} className="decor-line-top" />
              <Image src={LineBottom} className="decor-line-bottom" />
              <h6><b>TERMS OF SERVICE FOR REGISTERED USERS</b></h6>
              <h6><i>Last updated April 2022</i></h6>
              <p>
                <i>
                  These Terms of Service for Registered Users supplement and incorporate the Company’s Terms of Use available at https://www.gocobble.com/terms-of-use/.
                </i>
              </p>
              <p>
                Please read these Terms of Service for Registered Users (“<b>Terms of Service</b>”) carefully before agreeing to use the www.gocobble.com web application, any page thereof, any materials associated therewith or otherwise provided to you, or any services offered or provided by Cobble Ventures, Inc (“<b>Company</b>,” “<b>we</b>,” “<b>us</b>,” or “<b>our</b>”) (collectively, the “<b>Application</b>”). For purposes of these Terms of Service, “<b>you</b>” or “<b>your</b>” means the person(s) using this Application, both in such person’s individual capacity and in such person’s capacity as an employee or agent, if applicable.  
              </p>
              <p>
                <b>
                  Acceptance of These Terms
                </b>
              </p>
              <p>
                These Terms of Service apply to all users of the Application who have registered an account, whether paid or unpaid. These Terms of Service expand on the Company’s Terms of Use, available at https://www.gocobble.com/terms-of-use/ (the “<b>Terms of Use</b>” and collectively with these Terms of Service, the “<b>Terms</b>”), which remain binding on all users. The Terms of Use, along with the Company’s Privacy Policy available at https://www.gocobble.com/privacy-policy, are incorporated into these Terms as if fully set forth herein. 
              </p>
              <p>
                Your access to and use of the Application beyond the point at which you are prompted to acknowledge these Terms, and any future access thereto, is conditioned upon your acceptance of and compliance with these Terms. 
              </p>
              <p>
                <b>
                  BY ACCESSING OR USING THE APPLICATION, YOU AGREE (ON BEHALF OF YOURSELF AND ANY INDIVIDUAL OR ENTITY OF WHICH YOU ARE AN EMPLOYEE OR AGENT) TO BE BOUND BY THESE TERMS. IF YOU DISAGREE WITH ANY PART OF THESE TERMS THEN YOU DO NOT HAVE PERMISSION TO ACCESS AND MUST IMMEDIATELY CEASE USING THE APPLICATION.
                </b>
              </p>
              <p>
                <b>
                  Scope of Engagement
                </b>
              </p>
              <p>
                <i>
                  Provision of Access
                </i>
              </p>
              <p>
                Subject to and conditioned on your compliance with the terms and conditions of these Terms, we hereby grant you a non-exclusive, non-transferable right to access and use the Application. 
              </p>
              <p>
                In accessing the Application, you may opt to access certain services that may include the use of third-party applications and materials (collectively, “<b>Third-Party Tools</b>”). Such access is conditioned upon and governed by the terms applicable to any such Third-Party Tools. You hereby agree to review and comply with any such terms in addition to these Terms.
              </p>
              <p>
                In accessing the Application or any Third-Party Tools made available through the Application, you may opt to access certain services that require provision of your information to certain third-parties (“<b>Third Parties</b>“). By opting into such access, you thereby authorize us to share such information with such Third Parties, subject to and consistently with our Privacy Policy, consistently with the scope of your authorization.
              </p>
              <p>
                <i>
                  Acknowledgements 
                </i>
              </p>  
              <p>
                In addition to the acknowledgements set forth in the Terms of Use, you specifically acknowledge that: 
              </p>  
              <p className="mx-4">
                •	Individuals affiliated with the Company may also be affiliated with third-parties, including venture capital firms. These terms confer no obligations on such third parties. In accessing the Application, you should not expect any action, communication with, or results from any such third parties.
              </p>  
              <p className="mx-4">
                •	We make no guarantees regarding your use of the templates and materials made available through the Application.
              </p>  
              <p className="mx-4">
                •	The Application may link to and reference media created by certain third parties (including podcasts, articles, and other forms of media). We have made such media available for various purposes; however, we do not endorse or guarantee the accuracy of any such media. 
              </p>  
              <p className="mx-4">
                •	The framework provided via the Application is a guide only and may not fit your unique situation. You are responsible for analyzing any such framework and determining whether it makes sense to utilize, in whole or in part, for your specific situation. 
              </p>
              <p className="mx-4">
                •	The Application may identify certain third parties (such as investors, service providers, or other sources of funding). We do not endorse or recommend any such third parties. We do not endorse or recommend you to any such third parties. We do not act as your agent in facilitating or participating in any communications with any such third parties.
              </p> 
              <p>
                <i>
                  Authorized Users
                </i>
              </p>  
              <p>
                Access and use of the Application is solely for your employees, consultants, contractors, and agents (i) who are eligible to access and use the Application pursuant to the eligibility requirements set forth in these Terms, and (ii) if applicable, for whom access to the Application has been purchased (collectively, “Authorized Users”). We will provide the necessary passwords and network links to allow you to access the Application, and you hereby agree to treat such information as confidential. You agree to notify us immediately of any unauthorized access to or use of your username or password or any other breach of security.
              </p>  
              <p>
                You are responsible for all acts and omissions of Authorized Users and any user operating through your username and password.  Any such acts or omissions that would constitute a breach of these Terms if taken by you will be deemed a breach of these Terms by you.
              </p>  
              <p>
                <b>
                  User Contributions & Additional Commitments 
                </b>
              </p>  
              <p>
                <i>
                  User Contributions
                </i>
              </p>  
              <p>
                The Application may contain interactive features that allow you to submit information to the Company (“User Contributions”). In making any such submission, you represent and warrant that: 
              </p>  
              <p className="mx-4">
                •	You own or control all rights in and to the User Contributions;
              </p>  
              <p className="mx-4">
	              •	All information set forth in the User Contributions is accurate;
              </p>  
              <p className="mx-4">
                •	You are submitting the User Contributions for the purposes set forth in the feature soliciting such contributions; and
              </p>  
              <p className="mx-4">
                •	All of your User Contributions do and will comply with these Terms.   
              </p>  
              <p>
                You are solely responsible for the accuracy of any User Contribution. You specifically acknowledge and agree that (i) we are not responsible for the accuracy of any User Contribution; (ii) we do not verify or conduct diligence regarding the accuracy of any User Contribution; and (iii) if you opt to use any feature that would make any User Contribution available to a third party, such User Contribution may be disclosed to and relied upon by Counterparties.  
              </p>  
              <p>
                By submitting any User Contribution, you grant the Company a non-exclusive, royalty-free, worldwide license to display the information contained therein in the Application and, consistently with our Privacy Policy, to incorporate any such information into aggregated statistics. 
              </p>  
              <p>
                <i>
                  Additional Commitments 
                </i>
              </p>  
              <p>
                You hereby agree to receive any notices about the Application electronically, that your information technology systems meet the technical requirements for such receipt, and that you are able to access and retain copies of any notices or other information that is sent to you or otherwise made available to you electronically. 
              </p>  
              <p>
                <b>
                  Fees and Payment 
                </b>
              </p>
              <p>
                You hereby agree to pay any and all fees that are communicated to you by the Company and to which you have electronically acknowledged your consent, including but not limited to any fee imposed due to the renewal or autorenewal of any subscription. When you agree to utilize the paid portions of the Application, you will be required to input payment information for your credit card or other payment method. You hereby agree to have the Company charge you, via your credit card or other payment method, for the fees related to any Application that you have agreed to purchase, and to pay any such fees pursuant to any terms communicated to you by the Company. All fees paid by you or on your behalf to the Company are non-refundable. All fees must be paid in US dollars. 
              </p>  
              <p>
                <b>
                  Termination 
                </b>
              </p>  
              <p>
                In addition to the Monitoring and Enforcement rights set forth in the Terms of Use, and any other termination or suspension rights set forth therein, the Company may restrict access to your account at any time and for any reason. 
              </p>  
              <p>
                Upon termination of your right to access the Application, you shall immediately discontinue any use thereof. 
              </p>  
              <p>
                The Company may, following termination, delete or retain any User Contribution or other information consistently with the Privacy Policy. You should not expect to access or receive a copy of any User Contribution following termination of your account. 
              </p>  
              <p>
                <b>
                  Confidentiality 
                </b>
              </p>  
              <p>
                <i>
                  Your Confidential Information  
                </i>
              </p>  
              <p>
                We hereby agree to treat any User Contribution as “Confidential Information,” which we may use, display, or disclose for the purpose of providing and facilitating your use of the Application and any services ancillary thereto, including if applicable the provision of any User Contribution to Third Parties as authorized by you (the “Purpose”). We will not use or disclose your Confidential Information except as necessary to carry out the Purpose; provided, however, that pursuant to and consistent with the Privacy Policy, we may use your Confidential Information to compile aggregated statistics or otherwise use anonymized information to improve our business. 
              </p>
              <p>
                <i>
                  Our Information 
                </i>
              </p>  
              <p>
                You acknowledge that, by registering an account, you will be privy to certain Company confidential information, defined as any information about us, our business, or the Application itself (including but not limited to all aspects of your account, our processes, any third-party application to which you are granted access, the compilation or curation of any Third-Party Tools or any other third-party media or materials, among others) that is not publicly accessible (any such information, “<b>Company Information</b>”). You hereby agree (a) not to disclose or otherwise make available Company Information to any third party without the prior written consent of the Company (provided, however, that you may disclose the Company Information to your officers, employees, consultants, affiliates, and legal advisors who have a “need to know,” who have been apprised of this restriction, and who are themselves bound by nondisclosure obligations substantially similar to those set forth herein); (b) to use the Company Information only for the purposes of performing your obligations under these Terms or making use of the Application; and (c) to promptly notify the Company in the event you become aware of any loss or disclosure of any Company Information.  If you become legally compelled to disclose any Company Information, you shall provide: (a) prompt written notice of such requirement to us so that we may seek a protective order or other remedy; and (b) reasonable assistance in opposing such disclosure or seeking a protective order or other limitations on disclosure.
              </p>  
              <p>
                You agree to take and use particular caution when accessing the Application, especially if accessing from a public or shared computer, so that unauthorized third parties are not able to view or record Company Information.
              </p> 
              <p>
                <i>
                  Equitable Relief 
                </i>
              </p>  
              <p>
                You acknowledge and agree that any breach of your confidentiality obligations pursuant to this Section will cause injury to the Company and, in such event, money damages would be an inadequate remedy. As such, in addition to remedies at law, we shall be entitled to equitable relief as a remedy for any such breach.
              </p>
              <p>
                <b>
                  ADDITIONAL DISCLAIMERS; LIMITATION OF LIABILITY
                </b>
              </p>  
              <p>
                IN ADDITION TO THE GENERAL DISCLAIMER OF WARRANTIES SET FORTH IN THE TERMS OF USE, WE HEREBY DISCLAIM ALL WARRANTIES RELATED TO THE APPLICATION. UNDER NO CIRCUMSTANCES WILL THE COMPANY OR ANY OF ITS AFFILIATES BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY OUR PROVISIONS OF THE APPLICATION OR YOUR USE THEREOF. USE OF THIS APPLICATION IS AT YOUR OWN RISK.
              </p>  
              <p>
                IN NO EVENT WILL THE COMPANY BE LIABLE IN CONNECTION WITH THESE TERMS UNDER ANY LEGAL OR EQUITABLE THEORY (INCLUDING BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, AND OTHERWISE), FOR ANY: (a) CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, ENHANCED, OR PUNITIVE DAMAGES; (b) INCREASED COSTS, DIMINUTION IN VALUE OR LOST BUSINESS, PRODUCTION, REVENUES, OR PROFITS; (c) LOSS OF GOODWILL OR REPUTATION; (d) USE, INABILITY TO USE, LOSS, INTERRUPTION, DELAY OR RECOVERY OF ANY DATA, OR BREACH OF DATA OR SYSTEM SECURITY; OR (e) COST OF REPLACEMENT GOODS OR APPLICATION, IN EACH CASE REGARDLESS OF WHETHER THE COMPANY WAS ADVISED OF THE POSSIBILITY OF SUCH LOSSES OR DAMAGES OR SUCH LOSSES OR DAMAGES WERE OTHERWISE FORESEEABLE. 
              </p>  
              <p>
                THESE TERMS ARE INTENDED TO DISCLAIM ANY AND ALL LIABILITY TO YOU. TO THE EXTENT THE COMPANY IS FOUND LIABLE TO YOU BY A COURT OR OTHER BODY OF COMPETENT JURISDICTION, IN NO EVENT WILL THE COMPANY’S AGGREGATE LIABILITY ARISING OUT OF OR RELATED TO THESE TERMS, UNDER ANY LEGAL OR EQUITABLE THEORY (INCLUDING BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, AND OTHERWISE) EXCEED ONE TIMES THE TOTAL AMOUNTS PAID TO THE COMPANY UNDER THESE TERMS IN THE SIX-MONTH PERIOD PRECEDING THE EVENT GIVING RISE TO THE CLAIM.  
              </p>  
              <p>
                <b>
                  Indemnification 
                </b>
              </p>  
              <p>
                You hereby agree to indemnify and hold harmless, and at the Company’s option, defend the Company and its affiliates, from and against any and all liabilities, costs, expenses (including any reasonable attorneys’ fees), and other damages arising out of or related to claims resulting from or related to your use of the Application.  Notwithstanding the foregoing, if you do assume the obligation to defend such claims, you may not settle any such claim against the Company unless Company consents to such settlement, and further provided that the Company will have the right, at its option, to defend itself against any such third-party claim or to participate in the defense thereof by counsel of its own choice.
              </p>  
              <p>
                <b>
                  Changes to These Terms 
                </b>
              </p>  
              <p>
                We may revise and update these Terms from time to time in our sole discretion. All changes are effective immediately when we post them or otherwise notify you.  Your continued use of the Application following the posting or notification of revised Terms means that you accept and agree to the changes. 
              </p>  
              <p>
                <b>
                  All Other Provisions 
                </b>
              </p>  
              <p>
                All other provisions set forth in the Terms of Use, including but not limited to the sections captioned Governing Law and Jurisdiction; Waiver and Severability; and Entire Agreement, shall apply to these Terms.
              </p>  
            </Col>
          </Row>
        </Container>
      </Layout>
    )
}

export default TermOfService;
