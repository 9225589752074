import React from "react";
import { Container, Image, Row, Col, Button } from 'react-bootstrap';
import MarketImg from "../../../assets/images/investors/market.png";
import Decor from "../../../assets/images/investors/two_line_paint.svg";

const Market = () => {
  return (
    <div className="w=100 market-wrapper">
      <Container>
        <Row>
          <Col md={6} className="content-left">
            <Image src={Decor} className="market-decor" />
            <div className="content-block">
              <div className="title">
                <p>Diverse Markets</p>
                Invest In Great Potential
              </div>
              <div className="description">Find deals, no matter your investment thesis. Below are just a few of the markets startups using Cobble fall into. </div>
              <div className="markets">
                <Button className="market-button">SaaS</Button>
                <Button className="market-button">CPG</Button>
                <Button className="market-button">Internet Tech</Button>
                <Button className="market-button">FinTech</Button>
                <Button className="market-button">Edtech</Button>
                <Button className="market-button">Aerospace</Button>
                <Button className="market-button">BioTech</Button>
              </div>
            </div>
          </Col>
          <Col md={6} className="content-right">
            <Image src={MarketImg} className="market-img" />
          </Col>
        </Row>
      </Container>
    </div>
  )
};

export default Market;
