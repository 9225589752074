import React from "react";
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import Header from "./Header";
import Footer from "./Footer";

const Layout = ({ children }) => {
  return (
    <Container fluid className="layout-container investor-wrapper px-0">
      <Header />
      {children}
      <Footer />
    </Container>
  )
}

Layout.propTypes = {
  children: PropTypes.any
}

export default Layout;
