import React from "react";
import { Container, Image, Row, Col } from 'react-bootstrap';
import FundingImg from "../../../assets/images/investors/funding.png";
import DecorPaint from "../../../assets/images/investors/signup_paint.svg";
import DecorZigZag from "../../../assets/images/investors/three_line_zigzag.svg";

const Funding = () => {
  return (
    <Container className="funding-wrapper">
      <Row>
        <Col md={6} className="content-left">
          <Image src={DecorPaint} className="decor-paint" />
          <div className="content-block">
            <div className="title">
              {/* <p>Start Funding</p> */}
              Invest in Startups
            </div>
            <div className="description">With Cobble, access tons of startups with founders from a number of different backgrounds. The entrepreneurs using Cobble are scrappy and innovative. Fund founders with Cobble’s deal flow tool. </div>
          </div>
        </Col>
        <Col md={6} className="content-right">
          <Image src={DecorZigZag} className="decor-zigzag" />
          <Image src={FundingImg} className="startup-img" />
        </Col>
      </Row>
    </Container>
  )
};

export default Funding;
