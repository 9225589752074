import React from "react";
import { Image, Button } from 'react-bootstrap';
import logo from "../assets/images/white.svg";
import collapse from "../assets/images/collapse.png";
import arrowRight from "../assets/images/arrow-right.png";
import facebook from "../assets/images/facebook.png";
import twitter from "../assets/images/twitter.png";
import instagram from "../assets/images/instagram.png";
import PropTypes from 'prop-types';

const ToggleMenu = ({toggling, handleToogleMenu}) => {
  if(!toggling) return null;

  return (
    <div className="menu-collapse">
      <div className="menu-collapse-header">
        <Image className="header-logo-image" src={logo} width={183.5} height={38} />
        <Button className="header-nav-toggler" onClick={handleToogleMenu}>
          <Image src={collapse} width={21} height={21} />
        </Button>
      </div>
      <div className="menu-collapse-body">
        <ul className="menu-collapse-body-item">
          <li>
            Why Join?
            <span>
              <Button>
                <Image src={arrowRight} width={22} height={18} />
              </Button>
            </span>
          </li>
          <li>
            Our Missings
            <span>
              <Button>
                <Image src={arrowRight} width={22} height={18} />
              </Button>
            </span>
          </li>
        </ul>
        <a href={process.env.REACT_APP_SIGNUP_URL} className="header-button-join">Join Now</a>
      </div>
      <div className="menu-collapse-footer">
        <div className="menu-callapse-socials">
          <Image src={facebook} width={12} height={26} />
          <Image src={twitter} width={32} height={26} />
          <Image src={instagram} width={26} height={26} />
        </div>
        <div className="menu-callapse-copyright">
          {"© Cobble 2022. All Rights Reserved."}
        </div>
      </div>
    </div>
  )
}

ToggleMenu.propTypes = {
  handleToogleMenu: PropTypes.func,
  toggling: PropTypes.bool
}

export default ToggleMenu;
