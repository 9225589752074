import React from 'react';
import { Row, Col, Image, Container } from 'react-bootstrap';
import Layout from './components/Layout';
import TermBg from '../../assets/images/terms-header.png';
import TermBgDecor from '../../assets/images/term-header-bg.svg';
import ThunderIcon from '../../assets/images/thunder.svg';
import DotsIcon from '../../assets/images/dots.svg';
import DotsOrangeIcon from '../../assets/images/dots-orange.svg';
import TriangleIcon from '../../assets/images/triangle-blue.svg';
import LinesIcon from '../../assets/images/lines.svg';
import FooterIcon from '../../assets/images/terms-bottom-decor.png';
import LineTop from '../../assets/images/line-top.svg';
import LineBottom from '../../assets/images/line-bottom.svg';

const Policy = () => {
    return (
      <Layout>
        <Container className="term-wrapper">
          <Row>
            <Col sm="6" className="header-left">
              <h1>Our Privacy <br /><span>Policy</span></h1>
            </Col>
            <Col sm="6" className="header-right">
              <Image width={486} height={345} className="bg" src={TermBg} />
              <Image width={300} height={295} className="decor" src={TermBgDecor} />
            </Col>
          </Row>
          <Row>
            <Col sn="12" className="content position-relative">
              <Image src={ThunderIcon} className="decor-thunder" />
              <Image src={DotsIcon} className="decor-dots" />
              <Image src={DotsOrangeIcon} className="decor-dots-orange" />
              <Image src={TriangleIcon} className="decor-triangle" />
              <Image src={LinesIcon} className="decor-lines" />
              <Image src={FooterIcon} className="decor-footer" />
              <Image src={LineTop} className="decor-line-top" />
              <Image src={LineBottom} className="decor-line-bottom" />
              <h6>Last Updated: April 2022</h6>
              <p>
                Introduction
              </p>
              <p>
                This Website is owned and operated by Cobble Ventures, Inc. (“Company”, “we”, “us” or “our”). We want you to understand what information we gather about you, how we use it, and the safeguards we have in place to protect it.  For purposes of this Policy, “you” or “your” means the person(s) using the website www.gocobble.com and any page thereof, including but not limited to any information transmitted and collected through your account (the “<b>Website</b>”), or any services offered or provided by the Company (the “<b>Services</b>”), including any employees or agents acting on your behalf, both in such person’s individual capacity and in such person’s capacity as an employee or agent of another individual or entity, if applicable.   
              </p>
              <p>
                This privacy policy (this “<b>Policy</b>”) applies to information that we collect through the Website, in conjunction with our provision of the Services (including via email, text, or other electronic message), or otherwise by the Company. This Policy does not apply to information collected by any third party (including third parties with whom you interact through the Website or in connection with the Services).
              </p>
              <p>
                Please read this Policy carefully. By using the Website or the Services, you consent to this Policy and agree (on behalf of yourself and any individual or entity of which you are an employee or agent) that we may use and disclose information about you in the manner described below. This Policy may change from time to time (see Changes and Updates to this Policy), and your continued use of the Website or Services is deemed to be acceptance of any such changes.  
              </p>
              <p>
                <b>
                  Table of Contents 
                </b>
              </p>
              <p>
                Information We Collect
              </p>
              <p>
                How We Collect Your Information 
              </p>
              <p>
                How We Use Your Information 
              </p>
              <p>
                Disclosure of Your Information
              </p>
              <p>
                Your Choices Regarding Our Use and Disclosure of Information
              </p>
              <p>
                Data Security
              </p>
              <p>
                How Long We Keep Information 
              </p>
              <p>
                Right to Examine Information 
              </p>
              <p>
                State-Specific Rights 
              </p>
              <p>
                Changes and Updates to this Policy
              </p>
              <p>
                Children Under the Age of 18
              </p>
              <p>
                Miscellaneous
              </p>
              <p>
                <b>
                  Information We Collect
                </b>
              </p>
                The Company may collect personally identifiable information that can identify you such as your name, address, telephone number, and e-mail address (“<b>Personally Identifiable Information</b>” or “<b>PII</b>”). The Company may also collect information that does not identify you, such as demographic data, data about your online activity, information about your internet connection or equipment you use to access the Website (“<b>Non-Personally Identifiable Information</b>” or “<b>Non-PII</b>”). The Company may also collect and store information that you provide to it about other people (such as co-founders, contacts, and customers), including their respective names, addresses, telephone numbers, mobile devices, and email addresses (any such information, along with PII and Non-PII, the “<b>Information</b>”).

              <p>
                <b>
                  How We Collect Your Information 
                </b>
              </p>
              <p>
                The Company collects Information in the following ways:
              </p>
              <p className="mx-4">
	              •	Directly from you in connection with inquiring about or otherwise utilizing the Website or the Services; 
              </p>
              <p className="mx-4">
	              •	Automatically as you navigate the Website, including through cookies, web beacons and other internet technologies to collect information from your computer(s) and browser about your activities on our website; and 
              </p>
              <p className="mx-4">
                •	From third parties with whom you interact via the Website or to whom the Company is authorized to disclose Information in performance of the Services. 
              </p>
              <p>
                <i>
                  Information We Collect Automatically 
                </i>
              </p>
              <p>
                We also collect and store Information that is generated automatically as you navigate the Website and the Services. For example, we may collect information about your computer&apos;s connection to the Internet, which allows us, among other things, to improve the delivery of our web pages to you and to measure traffic on the Services. We also may use a standard feature found in browser software called a &quot;cookie&quot; to enhance your experience with the Services. Cookies are small files that your web browser places on your hard drive or mobile device for record-keeping purposes. By showing how and when visitors use the Services, cookies help us identify how many unique users visit us, and track user trends and patterns. They also prevent you from having to re-enter your preferences on certain areas of the Services where you may have entered preference information before. The Services also may use web beacons (single-pixel graphic files also known as &quot;transparent GIFs&quot;) to access cookies and to count users who visit the Services or open HTML-formatted email messages. We may also collect certain information from your mobile device, such as device and usage information that may include information that is specific to your mobile device (e.g. make, model, operating system, advertising identifier and similar information; (ii) information about your use of features, functions or notifications on the device; and (iii) signal strength relating to Wi-Fi or Bluetooth functionality, temperature, battery level, and similar technical data.
              </p>
              <p>
                <i>
                  Specific Disclosure of Third-Party Data Collection Technologies
                </i>
              </p>
              <p>
                In operating our business, we may use certain third-party analytical services, including but not limited to Google Analytics Advertiser Features.  Advertiser features include: Remarketing with Google Analytics; Google Display Network Impression Reporting; DoubleClick Platform integrations; Google Analytics Demographics and Interest Reporting, among others.
              </p>
              <p>
                By enabling these features, certain third parties require us to disclose the use of these features to our users and, specifically, that we and third-party vendors use first-party cookies (such as the Google Analytics cookie) or other first-party identifiers, and third-party cookies (such as the DoubleClick cookie) or other third-party identifiers together to gather data about your activities on our Services. Among other uses, this allows us to contact you if you begin to fill out our check-out form but abandon it before completion with an email reminding you to complete your order. In particular, the “Remarketing” feature allows us to reach people who previously visited our Services and match the right audience with the right advertising message.
              </p>
              <p>
                You may opt out of the automated collection of information by third-party ad networks for the purpose of delivering advertisements tailored to your interests, by visiting the consumer opt-out page for the Self-Regulatory Principles for Online Behavioral Advertising at http://www.aboutads.info/choices/ and edit or opt-out your Google Display Network ads’ preferences at http://www.google.com/ads/preferences/. Because those opt-out and preference control pages are specific to the individual browser used to visit it, and because that page is not operated by the Company, we are unable to perform the opt-outs on your behalf.
              </p>
              <p>
                Although we do not control third parties’ collection or use of your information to serve interest-based advertising, these third parties may provide you with ways to choose not to have your information collected or used in this way. You can opt out of receiving targeted ads from members of the Network Advertising Initiative (“NAI”) on the NAI’s website.
              </p>
              <p>
                <b>
                  How We Use Your Information 
                </b>
              </p>
              <p>
                The Company may use your Information to:
              </p>
              <p className="mx-4">
	              •	Present the Website and deliver the Services to you, including providing customer support to you;
              </p>
              <p className="mx-4">
	              •	Communicate with you about the Services or the Website, including any changes thereto;
              </p>
              <p className="mx-4">
	              •	Inform you of additional services offered by us or our affiliates;
              </p>
              <p className="mx-4">
	              •	Manage your relationship with the Company, including to carry out our obligations and enforce our rights arising from any contracts entered into between you and us;
              </p>
              <p className="mx-4">
	              •	Analyze your use the Services and the Website in order to optimize the Company’s business and performance;
              </p>
              <p className="mx-4">
	              •	Compile aggregated statistics, which once aggregated, the Company may use without restriction;
              </p>
              <p className="mx-4">
	              •	Evaluate interest from third parties with whom you may be interested in doing business, specifically regarding information that you affirmatively submit to us regarding potential services from such third parties; 
              </p>
              <p className="mx-4">
	              •	Comply with any legal obligation; and
              </p>
              <p className="mx-4">
	              •	For any other reason described to you at the time of collection.
              </p>
              <p>
                <b>
                  Disclosure of Your Information 
                </b>
              </p>
              <p>
                We may disclose Information as follows:
              </p>
              <p className="mx-4">
	              •	To our subsidiaries and affiliates; 
              </p>
              <p className="mx-4">
	              •	To contractors, service providers, and other third parties we use to support our business or that we believe may be a better fit for the services you are seeking;
              </p>
              <p className="mx-4">
	              •	To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of the Company’s assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by the Company is among the assets transferred;
              </p>
              <p className="mx-4">
	              •	To fulfill the purpose for which you provide it, including but not limited to by making authorized disclosures to third parties (see Note About Disclosure to Third Parties);
              </p>
              <p className="mx-4">
	              •	For any other purpose disclosed by us at the point at which you provide the information; 
              </p>
              <p className="mx-4">
	              •	With your consent; 
              </p>
              <p className="mx-4">
	              •	To government or regulatory authorities as we reasonably believe is necessary; 
              </p>
              <p className="mx-4">
	              •	To comply with any court order, law, or legal process, including to respond to any government or regulatory request; 
              </p>
              <p className="mx-4">
	              •	To investigate, prevent or take other action regarding actual or suspected illegal activity or other wrongdoing;
              </p>
              <p className="mx-4">
	              •	To enforce or apply our terms of use or any other agreement between you and the Company, including for billing purposes; and
              </p>
              <p className="mx-4">
                •	If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the Company, our agents and affiliates, our customers, or others. 
              </p>
              <p>
                To the extent your Information is incorporated within aggregated statistics (or other information that does not identify any particular individual or entity), we may disclose such anonymized information without restriction. 
              </p>
              <p>
                <i>
                  Note About Disclosure to Third Parties 
                </i>
              </p>
              <p>
                You specifically acknowledge that our provision of the Services may necessitate disclosure of Information to third parties. If, in using the Website or the Services, you (i) indicate an acceptance or agreement to such disclosure, or (ii) otherwise signify your agreement to proceed with an action or service that contemplates such disclosure, you thereby consent, acknowledge, and agree to our disclosure of your Information to any such third parties consistently with your agreement or acceptance. 
              </p>
              <p>
                <b>PLEASE NOTE</b> that, once the Information is disclosed to a third party, such party’s use of the Information is outside the scope of this Policy. Such parties may use and may retain or use such Information whether or not you choose to transact business with such party. You should contact such party directly concerning its respective privacy and information sharing practices and policies. 
              </p>
              <p>
                You should also be aware that the Website may contain links to other websites owned and operated by third parties. Should you submit any Information to such parties, whether directly or automatically by interacting with the link or website, such third parties’ use of such Information is outside the scope of this Policy.  
              </p>
              <p>
                <b>
                  Your Choices Regarding Our Use and Disclosure of Information 
                </b>
              </p>
              <p>
                Some of the ways in which we use and disclose your Information are necessary to our performance of the Services. However, if you wish to modify your preferences about the Company’s ability to contact you about additional services offered or other discretionary preferences, you may email privacy@gocobble.com. All of the messaging or emails that are sent to you by us also include an unsubscribe link, which will allow you to remove yourself at any time from our mailing list.
              </p>
              <p>
                You also can limit the Information we collect automatically. If you wish to limit the extent to which we use tracking technologies, you can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. To learn how you can manage your Flash cookie settings, visit the Flash player settings page on Adobe&apos;s website. If you disable or refuse cookies, please note that some parts of this site may then be inaccessible or not function properly.
              </p>
              <p>
                Please note that, depending on your location, you may have specific privacy rights due to state-specific regulations. To make a request under any such regulation, please email us at privacy@gocobble.com. 
              </p>
              <p>
                <b>
                  Data Security 
                </b>
              </p>
              <p>
                The Company utilizes security measures that are standard in the industry (including physical, electronic and procedural measures) to help safeguard your Information from unauthorized access and disclosure. Your Information is stored at the server that delivers our content and messaging through the Services. In addition, we require employees to comply with information security safeguards, we use encryption in the transmission of your PII between your system and ours, and we use firewalls to help prevent unauthorized persons from gaining access to your PII.
              </p>
              <p>
                The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our Website, you are responsible for keeping this password confidential. We ask you not to share your password with anyone. 
              </p>
              <p>
                Because no system can be completely secure, the Company cannot, and does not, guarantee that your PII or other Information will always remain secure. <b>As such, notwithstanding any statement regarding the Company’s data security practices contained herein, any transmission of Information, including PII, is at your own risk.</b>
              </p>
              <p>
                <b>
                  How Long We Keep Information
                </b>
              </p>
              <p>
                Subject to the data security and other disclaimers set forth in this policy, we will keep your Information for as long as is necessary for you obtain the benefit of the Services. Thereafter, we consider several factors to determine how long we keep your Information. We consider the amount, nature, and sensitivity of the Information, your preferences regarding Information as communicated to us in writing, the potential risk of harm from unauthorized use or disclosure of your Information, the purposes for which we process your Information and whether we can achieve those purposes through other means, and our contractual and other legal requirements. When we do destroy your Information, we will securely destroy it in accordance with applicable laws and regulations.
              </p>
              <p>
                Following termination of any account you create with us, you should not expect us to retain any Information provided by you. We do not guarantee that we will retain any such Information in any format that can be provided to you. 
              </p>
              <p>
                <b>
                  Right To Examine Information
                </b>
              </p>
              <p>
                You have the right to examine any of your PII that we collected over the previous twelve months. Should you wish to examine such information, please send us a written request to privacy@gocobble.com. We reserve the right to charge you a reasonable administrative fee to access your information, as permitted by applicable law. In certain cases we may not be able to provide you with access to all of your PII. We may decline to process requests that are frivolous, relating to information also pertaining to the PII of another individual, are unreasonable, or for which access is not otherwise required by local law. We may also decline aspects of deletion or access requests if we believe doing so would undermine our legitimate use of data for anti-fraud and security purposes as described earlier.
              </p>
              <p>
                <b>
                  State-Specific Rights
                </b>
              </p>
              <p>
                The California Consumer Privacy Act provides Customers located in California with specific access and deletion rights regarding the personal information about you that we collect, use, and disclose. Other state laws offer or may offer similar state-specific rights. You can exercise your rights by contacting us at privacy@gocobble.com. If you choose to exercise your privacy rights, you will not receive discriminatory treatment or a lesser degree of service from the Company.
              </p>
              <p>
                <b>
                  Changes and Updates to this Policy
                </b>
              </p>
              <p>
                The Company reserves the right to update or modify this Policy to reflect changes to how the Company collects, uses and/or shares Information. This Policy will reflect the date it was last updated. If the Company makes any material changes to this Policy, you will be notified before their implementation either by a prominent posting of a notice of such changes on your account homepage or by sending you electronic notification of such changes. Please check this Policy whenever you use or access the Website or utilize the Services in order to ensure that you are aware of any changes in the Company’s practices. Your continued use of the Website or the Services will constitute your acceptance of the changes to this Policy.
              </p>
              <p>
                <b>
                  Children Under the Age of 18
                </b>
              </p>
              <p>
                Neither the Website nor the Services is intended for children under the age of 18.  The Company does not knowingly collect personal information from users under the age of 18. If we become aware that we have inadvertently received personally identifiable information from a user under the age of 18, we will delete such information from our records. To find more information about the Children’s Online Privacy Protection Act (COPPA), view the FTC website at www.ftc.gov.
              </p>
              <p>
                <b>
                  Miscellaneous
                </b>
              </p>
              <p>
                This Policy is intended to cover collection of information on the Website from residents of the United States. If you are visiting the Website from outside the United States, please be aware that your information may be transferred to, stored and processed in the United States where the Company’s servers are located and the Company’s central database is operated. The data protection and other laws of the United States might not be as comprehensive as those in your country. By using the Website or the Services, you understand that your information may be transferred to the Company’s facilities and those third parties with whom the Company share Information, as described in this Policy.
              </p>
              <p>
                This Policy does not create rights enforceable by third parties or require disclosure of any PII.
              </p>
              <p>
                If you have any questions about this Policy or the Company’s information-handling practices generally, please contact the Company by e-mail at privacy@gocobble.com.
              </p>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
}

export default Policy;
