import React from 'react'
import {Button, Image, Row, Col, Container } from 'react-bootstrap';
import women from '../../assets/images/womens.png';
// import spiral from '../../assets/images/spiral.png';
import people from '../../assets/images/people.png';
import tick from '../../assets/images/right-tick.png';
import yellow from '../../assets/images/yellow-back.png';
import yellowspiral from '../../assets/images/yellow-spiral.png';
import arlene from '../../assets/images/arlene.png';
import code from '../../assets/images/code.png';
import kathryn from '../../assets/images/kathryn.png';
import guy from '../../assets/images/guy.png';
import yellowtick from '../../assets/images/yellow-tick.png';
import star from '../../assets/images/star.png';
import ostar from '../../assets/images/o-star.png';
import oostar from '../../assets/images/oo-star.png';
import lbstar from '../../assets/images/lb-star.png';
import bstar from '../../assets/images/b-star.png';
import blue from '../../assets/images/bluestar.png';
import wind from '../../assets/images/wind.png';
import child from '../../assets/images/children.png';
import grey from '../../assets/images/grey-background.png';
import one from '../../assets/images/one.png';
import two from '../../assets/images/two.png';
import three from '../../assets/images/three.png';
import seventh from '../../assets/images/seventh_right.png';
import dots from '../../assets/images/pattern-dots.png';
// import devon from '../../assets/images/devon.png';
// import theresa from '../../assets/images/theresa.png';
// import left from '../../assets/images/left-arrow.svg';
// import right from '../../assets/images/right-arrow.svg';
import hollow from '../../assets/images/hollow.png';
import SliderContent from '../../components/Slider';

import Layout from '../../components/Layout';
import './Partner.scss';
const Partner = () => {
  return (
    <Layout>
      <div className='partners'>
        <Row className='first'>
          <Col md="6" sm="12" className='left'>
            {/* <Image src={spiral} className='spiral'/> */}
            <div className='content'>
              <span className='join'>Join Cobble’s</span>
              <span className='network'>Partner Network</span>
              <span className='desc'>Interested in working with Cobble? Together we can make building a company more accessible and enjoyable for founders everywhere. </span>
              <Button className='btn' href='https://airtable.com/shrg4RWLJHwrfQ3Or' target="_blank">Inquire</Button>
            </div>
          </Col>
          <Col md="6" sm="12" className='right'>
            <Image src={women} className='women'/>
          </Col>
        </Row>
        <Row className='second'>
          <Col md="6" sm="12" className='left'>
            <Image src={people} className='img'/>
          </Col>
          <Col md="6" sm="12" className='right'>
            <Image src={yellow} className='yellow'/> 
            <span className='sub-head'>How to</span>
            <span className='head'>Work With Cobble</span>
            <span className='desc'>At Cobble, we believe that one of the best ways to make a meaningful impact is through partnership. We work with other organizations in various ways to bring more value to our users and the wider startup community. Let’s join forces through marketing, community building, or even an API.</span>
            <div className='tick-container'>
              <span className='content'><Image src={tick} className='tick'/> In-app featured content</span>
              <span className='content'><Image src={tick} className='tick'/>Newsletter feature</span>
              <span className='content'><Image src={tick} className='tick'/>Webinars</span>
              <span className='content'><Image src={tick} className='tick'/>Social media</span>
            </div>
            <Button className='btn' href='https://airtable.com/shrg4RWLJHwrfQ3Or' target="_blank">Get Started</Button>
          </Col>
          <Image src={yellowspiral} className='yellow-spiral' />
        </Row>
        <Row className='third' style={{display:'none'}}>
          <div className='content'>
            <span className='sub-head'>Our</span>
            <span className='head'>Network & People</span>
          </div>
          <div className='people'>
            <div className='content arlene'>
              <Image src={arlene} className='img'/>
              <span className='name'>Arlene McCoy</span>
              <span className='position'><Image src={yellowtick}/>Founder</span>
              <span className='position'><Image src={yellowtick}/>VC Investor</span>
            </div>
            <div className='content code'>
              <Image src={code} className='img' />
              <span className='name'>Code Fisher</span>
              <span className='position'><Image src={yellowtick}/>Co founder</span>
              <span className='position'><Image src={yellowtick}/>VC Investor</span>
            </div>
            <div className='content kathryn'>
              <Image src={kathryn} className='img'/>
              <span className='name'>Kathryn Murphy</span>
              <span className='position'><Image src={yellowtick}/>CTOO</span>
              <span className='position'><Image src={yellowtick}/>VC Investor</span>
            </div>
            <div className='content guy'>
              <Image src={guy} className='img' />
              <span className='name'>Guy Hawkins</span>
              <span className='position'><Image src={yellowtick}/>CFOO</span>
              <span className='position'><Image src={yellowtick}/>VC Investor</span>
            </div>
          </div>
        </Row>
        <Row className='fourth'>
          <Image src={wind} className='wind'/>
          <div className='content'>
            <span className='head'>Our Partner <b>Benefits</b></span>
            <span className='desc'>We work together to achieve common goals and make an impact. </span>
          </div>
          <div className='star-containers'>
            <div className='star-container'>
              <Image src={star} className='star'/>
              <div className='star-content'>
                <span className='head'>Reach a new market</span>
                <span className='desc'>Are you trying to tap into a new customer base? Cobble can help your reach more entrepreneurs and startup founders.</span>
              </div>
            </div>
            <div  className='star-container'>
              <Image src={ostar} className='star'/>
              <div className='star-content'>
                <span className='head'>Land more customers</span>
                <span className='desc'>With more exposure, you’ll be sure to bring in more users and customers to your platform.</span>
              </div>
            </div>
            <div  className='star-container'>
              <Image src={oostar} className='star'/>
              <div className='star-content'>
                <span className='head'>Build resources</span>
                <span className='desc'>Working together means collaboration, sharing resources, and learning new things.</span>
              </div>
            </div>
            <div  className='star-container'>
              <Image src={lbstar} className='star'/>
              <div className='star-content'>
                <span className='head'>Increase awareness</span>
                <span className='desc'>By broadcasting your brand to our audience, even more founders will know about your business.</span>
              </div>
            </div>
            <div  className='star-container'>
              <Image src={bstar} className='star'/>
              <div className='star-content'>
                <span className='head'>Add value</span>
                <span className='desc'>A partnership with Cobble helps you provide value to your current customers. </span>
              </div>
            </div>
            <div  className='star-container'>
              <Image src={blue} className='star'/>
              <div className='star-content'>
                <span className='head'>Earn trust</span>
                <span className='desc'>Build a more trustworthy brand through a relationship with Cobble.</span>
              </div>
            </div>
          </div>
        </Row>
        <Row className='fifth'>
          <Col md="6" sm="12" className='left'>
            <Image src={grey} className='grey'/>
            <div className='content'>  
              <span className='head'>Let&apos;s Get <b>Started</b></span>
              <span className='desc'>The first step to working with Cobble is expressing interest. Share why you’re interested in partnering and how you want to collaborate with us by filling out an application.</span>
              <Button className='btn' href='https://airtable.com/shrg4RWLJHwrfQ3Or' target="_blank">Get Started</Button>
            </div>
          </Col>
          <Col md="6" sm="12" className='right'>
            <Image src={child} className='img'/>
          </Col>
        </Row>
        <Row className='sixth'>
          <span className='heading'>How it Works</span>
          <div className='it-containers'>
            <div className='container container-1'>
              <div><Image src={one} className='logo'/></div>
              <div className='content'>
                <span className='head'>Express Interest</span>
                <span className='desc'>Fill out a brief Airtable form to let us know that you’d like to join forces.</span>
              </div>
            </div>
            <div className='container container-2'>
              <div><Image src={two} className='logo'/></div>
              <div className='content'>
                <span className='head'>Video Chat</span>
                <span className='desc'>Once we receive your submission, the Cobble team will reach out to schedule an intro call. </span>
              </div>
            </div>
            <div className='container container-3'>
              <div><Image src={three} className='logo'/></div>
              <div className='content'>
                <span className='head'>Send Proposal</span>
                <span className='desc'>After learning more about each other, we’ll work together to finalize the propsal. </span>
              </div>
            </div>
          </div>
        </Row>
        <Row className='seventh'>
          <Image src={dots} className='dots'/>
          <Col md="6" sm="12" className='left'>
            <Image src={yellow} className='yellow'/>
            <div className='content'>
              <div className='sub-head'>Interested?</div>
              <div className='head'>Here’s What You Need</div>
              <div className='desc'>We can’t wait to hear from you! Here’s what you’ll need to prepare before you reach out:</div>
              <div>
                <div className='lining'><Image src={tick} /><span className='below-desc'>Description of your organization</span></div>
                <div className='lining'><Image src={tick} /><span className='below-desc'>Why our partnership would be a good fit</span></div>
                <div className='lining'><Image src={tick} /><span className='below-desc'>Specific examples of how we’ll collaborate</span></div>
              </div>
              <Button className='btn' href='https://airtable.com/shrg4RWLJHwrfQ3Or' target="_blank">Get in Touch</Button>
            </div>
          </Col>
          <Col md="6" sm="12" className='right'>
            <Image src={seventh} className='img'/>
          </Col>
        </Row>
        <Row className='eight' style={{display: 'none'}}>
          <Image src={hollow} className='hollow'/>
          <div className='head'>Our Partners</div>
          <div className='slider-containers'>
            <SliderContent />
          </div>
          {/* <div className='arrows'>
            <Image src={left} className='img'/>
            <Image src={right} className='img'/>
          </div> */}
        </Row>
          <div className="home-final-container">
            <Container className="home-final-content width-auto">
              <div className="home-content">
                <span>{"Let's Start building"}</span>
                <p>Get in on</p>
                <p>the Action</p>
              </div>
              <a href='https://app.gocobble.com/signup' target="_blank" className="home-final-content-button" rel="noreferrer">Try it Free!</a>
            </Container>
          </div>
      </div>
    </Layout>
  )
}

export default Partner