import React from "react";
import { Container, Image, Row, Col } from 'react-bootstrap';
import Signup from "../../../assets/images/investors/signup.png";
import SignupDecorDot from "../../../assets/images/investors/signup_dot.svg";
import DecorPaint from "../../../assets/images/investors/signup_paint.svg";
import DecorZigZag from "../../../assets/images/investors/signup_zigzag.svg";

const Profile = () => {
  return (
    <Container className="profile-wrapper">
      <Row>
        <Col md={6} className="content-left">
          <Image src={Signup} className="signup-img" />
          <Image src={SignupDecorDot} className="signup-dot" />
        </Col>
        <Col md={6} className="content-right">
          <div className="content-block">
            <div className="title">
              <p>Create an</p>
              Investor Profile
            </div>
            <div className="description">Show entrepreneurs who you are with an investor profile in Cobble. Share investment criteria including check size, verticals, and stage of startups to get access to the most qualified deals.</div>
          </div>
          <Image src={DecorPaint} className="decor-paint" />
          <Image src={DecorZigZag} className="decor-dot" />
        </Col>
      </Row>
    </Container>
  )
};

export default Profile;
