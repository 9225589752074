import React from 'react';
import Layout from './components/Layout';
import Signup from './parts/Signup';
import Profile from './parts/Profile';
import CRM from './parts/CRM';
import Startup from './parts/Startup';
import Funding from './parts/Funding';
import Market from './parts/Market';
import Footer from './parts/Footer';

const Investor = () => {
    return (
      <Layout>
        <Signup />
        <Profile />
        <Startup />
        <CRM />
        <Funding />
        <Market />
        <Footer />
      </Layout>
    )
}

export default Investor;
