import React from "react";
import { Container, Image, Row, Col } from 'react-bootstrap';
import FooterImg from "../../../assets/images/investors/footer.png";
import DecorLine from "../../../assets/images/investors/footer_line.svg";
import DecorZigZag from "../../../assets/images/investors/footer_zigzag.svg";

const Footer = () => {
  return (
    <Container className="footer-block-wrapper">
      <Row>
        <Col md={6} className="content-left">
          <Image src={FooterImg} className="signup-img" />
        </Col>
        <Col md={6} className="content-right">
          <div className="content-block">
            <div className="title">
              <span>Get </span>Cobble
            </div>
            <div className="description">Join other investors and sign up for Cobble to explore qualified startup deal flow and manage contacts, communications, and tasks.</div>
            <a href={process.env.REACT_APP_SIGNUP_URL} className="signup-button">Get Started</a>
          </div>
          <Image src={DecorLine} className="decor-line" />
          <Image src={DecorZigZag} className="decor-zigzag" />
        </Col>
      </Row>
    </Container>
  )
};

export default Footer;
