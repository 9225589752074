import React from "react";
import { Container, Image, Row, Col } from 'react-bootstrap';
import CRMImg from "../../../assets/images/investors/crm.png";
import DecorPaint from "../../../assets/images/investors/signup_paint.svg";

const CRM = () => {
  return (
    <Container className="profile-wrapper">
      <Row>
        <Col md={6} className="content-left">
          <Image src={CRMImg} className="signup-img" />
        </Col>
        <Col md={6} className="content-right">
          <div className="content-block">
            <div className="title">
              <p>Manage Deal Flow</p>
              with an Investor CRM
            </div>
            <div className="description">Ditch the spreadsheets and use Cobble’s investor CRM to organize your deal flow pipeline. The intuitive dashboard gives you a quick summary of your investments that are in progress while the personal CRM helps you manage contacts, notes, meetings, and daily tasks. Set reminders and follow-up tasks to improve relationships with peers and founders.</div>
          </div>
          <Image src={DecorPaint} className="decor-paint" />
        </Col>
      </Row>
    </Container>
  )
};

export default CRM;
