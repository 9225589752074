import React from "react";
import { Container, Image, Navbar, Nav, Form } from 'react-bootstrap';
import { Link } from "react-router-dom";
import logo from "../../../assets/images/dark.svg";

const Header = () => {
  return (
    <div className="w-100 header-wrapper">
      <Container className="Layout-container">
        <div className="header-container w-100">
          <Navbar collapseOnSelect className="header" expand="lg">
            <Navbar.Brand className="header-logo">
              <Image className="header-logo-image" src={logo} width={145} height={40} />
            </Navbar.Brand>
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav as={Link} className="header-tabs" to="/">
                Home
              </Nav>
              <Nav className="header-tabs">
                Mission
              </Nav>
              <Nav className="header-tabs">
                Pricing
              </Nav>
              <Nav as={Link} className="header-tabs" to="/partners">
                Partners
              </Nav>
              <Nav as={Link} className="header-tabs active" to="/investors">
                For Investors
              </Nav>
            </Navbar.Collapse>
            <Navbar>
              <Form inline>
                <div className="header-buttons">
                  <a href={process.env.REACT_APP_LOGIN_URL} className='header-button-login header-button-login__black'>Log In</a>
                  <a href={process.env.REACT_APP_SIGNUP_URL} className="header-button-join">Join Now</a>
                </div>
              </Form>
            </Navbar>
          </Navbar>
        </div>
      </Container>
    </div>
  )
};

export default Header;
