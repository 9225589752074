import React from 'react';
import { Row, Col, Image, Container } from 'react-bootstrap';
import Layout from './components/Layout';
import TermBg from '../../assets/images/terms-header.png';
import TermBgDecor from '../../assets/images/term-header-bg.svg';
import ThunderIcon from '../../assets/images/thunder.svg';
import DotsIcon from '../../assets/images/dots.svg';
import DotsOrangeIcon from '../../assets/images/dots-orange.svg';
import TriangleIcon from '../../assets/images/triangle-blue.svg';
import LinesIcon from '../../assets/images/lines.svg';
import FooterIcon from '../../assets/images/terms-bottom-decor.png';
import LineTop from '../../assets/images/line-top.svg';
import LineBottom from '../../assets/images/line-bottom.svg';

const Term = () => {
    return (
      <Layout>
        <Container className="term-wrapper">
          <Row>
            <Col sm="6" className="header-left">
              <h1>Our Terms & <br /><span>Conditions</span></h1>
            </Col>
            <Col sm="6" className="header-right">
              <Image width={486} height={345} className="bg" src={TermBg} />
              <Image width={300} height={295} className="decor" src={TermBgDecor} />
            </Col>
          </Row>
          <Row>
            <Col sn="12" className="content position-relative">
              <Image src={ThunderIcon} className="decor-thunder" />
              <Image src={DotsIcon} className="decor-dots" />
              <Image src={DotsOrangeIcon} className="decor-dots-orange" />
              <Image src={TriangleIcon} className="decor-triangle" />
              <Image src={LinesIcon} className="decor-lines" />
              <Image src={FooterIcon} className="decor-footer" />
              <Image src={LineTop} className="decor-line-top" />
              <Image src={LineBottom} className="decor-line-bottom" />
              <h6><b>TERMS OF USE</b></h6>
              <h6><i>Last updated April 2022</i></h6>
              <p>
                Please read these Terms of Use (“<b>Terms</b>”) carefully before agreeing to use the www.gocobble.com website or any page thereof (collectively, the “<b>Website</b>”) or any services (the “<b>Services</b>”) offered or provided by Cobble Ventures, Inc. (“<b>Company</b>,” “<b>we</b>,” “<b>us</b>,” or “<b>our</b>”). For purposes of these Terms, “<b>you</b>” or “<b>your</b>” means the person(s) using this Website, and/or the Services, both in such person’s individual capacity and in such person’s capacity as an employee or agent of another individual or entity, if applicable.  
              </p>
              <p>
                <b>
                  Acceptance of These Terms
                </b>
              </p>
              <p>
                Your access to and use of the Website and the Service is conditioned upon your acceptance of and compliance with these Terms. These Terms apply to all visitors and users (registered and unregistered) who wish to access or use the Website or Service. 
              </p>
              <p>
                BY ACCESSING OR USING THE WEBSITE OR SERVICE, YOU AGREE (ON BEHALF OF YOURSELF AND ANY INDIVIDUAL OR ENTITY OF WHICH YOU ARE AN EMPLOYEE OR AGENT) TO BE BOUND BY THESE TERMS. IF YOU DISAGREE WITH ANY PART OF THESE TERMS THEN YOU DO NOT HAVE PERMISSION TO ACCESS AND MUST IMMEDIATELY CEASE USING THE WEBSITE OR SERVICES.
              </p>
              <p>
                The owner of the Website is incorporated in Delaware, United States, with a principal place of business in Columbus, Ohio. This Website is intended for individuals who are at least 18 years of age and reside in the United States or any of its possessions or territories. By using this Website, you represent and warrant that you meet all of the foregoing eligibility requirements. If you do not meet all of these requirements, you must not access or use the Website.
              </p>
              <p>
                <b>
                  Acknowledgements
                </b>
              </p>
              <p>
                The Website and the Services are administrative in nature. <b>We DO NOT, as part of the Services, the Website, or otherwise</b>:
              </p>
              <p>
                •	Guarantee funding, milestones, or success of any kind; or
              </p>
              <p>
                •	Provide financial, legal, accounting, or investment advice.
              </p>
              <p>
                <b>
                  Scope of Use 
                </b>
              </p>
              <p>
                <i>
                  Use of the Website and Services
                </i>
              </p>
              <p>
                You may use the Website and the Services only for their stated purposes. We reserve the right to withdraw or amend the Website, the Services, or any materials otherwise made available to you (including but not limited to any information provided to the Company by third parties) (the “<b>Materials</b>” and collectively with the Website and Services, the “<b>Application</b>”) in our sole discretion without notice. This right includes changing or discontinuing any aspect or feature of the Website or Services or any portion thereof. 
              </p>
              <p>
                We will not be liable if for any reason all or any part of the Application is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Application or the entire Application. We may update the content provided with the Application at any time.  
              </p>
              <p>
                You are responsible for ensuring that all persons who access the Application through your internet connection are aware of these Terms and comply with them.
              </p>
              <p>
                <i>
                  Prohibited Activities 
                </i>
              </p>
              <p>
                You shall not, and shall not permit any other person to, access or use the Application except as expressly permitted by these Terms.  
              </p>
              <p>
                For purposes of clarity and without limiting the generality of the foregoing, except as expressly permitted by these Terms, you shall not:
              </p>
              <p className="mx-4">
                •	make the Application available to any person, including on or in connection with the internet or any time-sharing, service bureau, software as a service, cloud or other technology or service;
              </p>
              <p className="mx-4">
                •	bypass or breach any security device or protection used by the Application or access or use the Application other than by your own use of your then valid access credentials;
              </p>
              <p className="mx-4">
                •	input, upload, transmit or otherwise provide to or through the Application any information or materials that are or could be unlawful or injurious, or contain, transmit or activate any harmful code or could otherwise interfere with the proper working of the Application;
              </p>
              <p className="mx-4">
                •	use any robot, spider, or other automatic device, process, or means to access the Application for any purpose, including monitoring or copying any of the material on the Application;
              </p>
              <p className="mx-4">
                •	use the Website in any manner that could disable, overburden, damage, or impair the site or interfere with any other party&lsquo;s use of the Application, including their ability to engage in real time activities through the Application;
              </p>
              <p className="mx-4">
                •	damage, destroy, disrupt, disable, impair, interfere with or otherwise impede or harm in any manner the Application, our systems or out provision of services to any third party, in whole or in part;
              </p>
              <p className="mx-4">
                •	remove, delete, alter or obscure any trademarks, specifications, documentation, warranties or disclaimers, or any copyright, trademark, patent or other intellectual property or proprietary rights notices from the Application, including any copy thereof;
              </p>
              <p className="mx-4">
                •	access or use the Application in any manner that violates any applicable law or regulation (including, without limitation, any laws regarding the export of data or software to and from the US or other countries), or right of a third party (including without limitation the intellectual property rights of any third party);
              </p>
              <p className="mx-4">
                •	access or use the Application for purposes of competitive analysis of the Application, the development, provision or use of a competing software service or product or any other purpose that is to the Provider&lsquo;s detriment or commercial disadvantage; 
              </p>
              <p className="mx-4">
                •	use any manual process to monitor or copy any of the material on the Application, or for any other purpose not expressly authorized in these Terms, without our prior written consent;
              </p>
              <p className="mx-4">
                •	otherwise access or use the Application beyond the scope of the authorization granted hereunder; or
              </p>
              <p className="mx-4">
                •	attempt to do any of the foregoing. 
              </p>
              <p>
                <b>
                  Intellectual Property Rights
                </b>
              </p>
              <p>
                The Application and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof) are owned by the Company, its licensors, or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws. 
              </p>
              <p>
                No right, title, or interest in or to the Application or any content on the Application is transferred to you, either directly or by estoppel or otherwise, and all rights not expressly granted are reserved by the Company. 
              </p>
              <p>
                You shall not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, copy, modify, print, store, or transmit any of the material on the Application, except as follows:
              </p>
              <p className="mx-4">
                •	Your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials.
              </p>
              <p className="mx-4">
                •	You may store files that are automatically cached by your Web browser for display enhancement purposes.
              </p>
              <p className="mx-4">
                •	You may print or download one copy of a reasonable number of pages of the Website or Materials for your own personal, non-commercial use and not for further reproduction, publication, or distribution.
              </p>
              <p className="mx-4">
                •	If we provide desktop, mobile, or other applications for download, you may download a single copy to your computer or mobile device solely for your own personal, non-commercial use, provided you agree to be bound by any required end user license agreement for such applications.
              </p>
              <p className="mx-4">
                •	If we provide social media features with certain content, you may take such actions as are enabled by such features.
              </p>
              <p>
                Specifically but without limiting the general restriction on authorized use as set forth above, you must not:
              </p>
              <p className="mx-4">
                •	Reverse engineer, disassemble, decompile, decode, adapt or otherwise attempt to derive or gain access to the source code of the Application, in whole or in part;
              </p>
              <p className="mx-4">
                •	Use any part of the Application for your own commercial purposes;
              </p>
              <p className="mx-4">
                •	Exploit any of the content set forth on the Website or the Materials; 
              </p>
              <p className="mx-4">
                •	Use any illustrations, photographs, video or audio sequences, or any graphics separately from the accompanying text;
              </p>
              <p className="mx-4">
                •	Delete or alter any copyright, trademark, or other proprietary rights notices from copies of Application; or
              </p>
              <p className="mx-4">
                •	Attempt to do any of the foregoing. 
              </p>
              <p>
                <i>
                  Trademarks 
                </i>
              </p>
              <p>
                The Company name, the Company logo, and all related names, logos, product and service names, designs, and slogans are trademarks of the Company or its affiliates or licensors. You agree to not use such marks without the prior written permission of the Company. All other names, logos, product and service names, designs, and slogans on this Website, Materials or in our provision of the Services (including the names of any third parties with whom you communicate through the Website or by virtue of the Application) may be the trademarks of their respective owners. You agree not to use any such trademarks without the prior written permission of the owner. 
              </p>
              <p>
                <i>
                  Other Media
                </i>
              </p>
              <p>
                You may link to our homepage, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it, but you must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part.
              </p>
              <p>
                The Application may provide certain features, which you may use solely as they are provided by us. Subject to the foregoing, you must not:
              </p>
              <p className="mx-4">
                •	Establish a link from any website that is not owned by you.
              </p>
              <p className="mx-4">
                •	Cause the Application or portions of it to be displayed, or appear to be displayed by, for example, framing, deep linking or in-line linking, on any other site.
              </p>
              <p className="mx-4">
                •	Link to any part of the Application other than the homepage of the Website.
              </p>
              <p>
                You agree to cooperate with us in causing any unauthorized framing or linking immediately to cease. We reserve the right to withdraw linking permission without notice. We may disable all or any features and any links at any time without notice in our discretion.
              </p>
              <p>
                <b>
                  Monitoring and Enforcement 
                </b>
              </p>
              <p>
                We may monitor your use of the Application and will determine, in our sole discretion, whether there has been a breach of these Terms. When a breach of these terms has occurred, we may take such action as we deem to be appropriate.
              </p>
              <p>
                If we suspect a breach of these Terms, you agree that we may, with or without prior notice take any or all of the following actions:
              </p>
              <p className="mx-4">
                •	immediately, temporarily or permanently withdraw your right to use the Application;
              </p>
              <p className="mx-4">
                •	issue a warning to you; 
              </p>
              <p className="mx-4">
                •	commence legal proceedings against you, including reimbursement of all costs on an indemnity basis (including but not limited to the reasonable administrative and legal costs resulting from the breach); and
              </p>
              <p className="mx-4">
                •	disclose relevant information to any government or regulatory authorities as we reasonably feel is necessary. 
              </p>
              <p>
                In the event of termination due to a suspected breach of the Company’s intellectual property rights, you agree to immediately return or destroy any copies of the Website or Materials you have made. In the event of termination generally, all provisions of these Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability. 
              </p>
              <p>
                The responses described in these Terms of Use are not exhaustive and we may take any other action we reasonably deem appropriate, including but not limited to pursuing legal or equitable action against you.
              </p>
              <p>
                YOU HEREBY WAIVE AND HOLD HARMLESS THE COMPANY AND ITS AFFILIATES, LICENSEES, AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN DURING, OR TAKEN AS A CONSEQUENCE OF, INVESTIGATIONS BY EITHER THE COMPANY, SUCH PARTIES, OR LAW ENFORCEMENT AUTHORITIES.
              </p>
              <p>
                <b>
                  DISCLAIMERS; LIMITATION OF LIABILITY
                </b>
              </p>
              <p>
                You understand that we cannot and do not guarantee or warrant that files available for downloading from Application will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our site for any reconstruction of any lost data. Your use of the Application is at your own risk. 
              </p>
              <p>
                You acknowledge and agree that we may use third-party vendors and hosting partners to provide hardware, software, networking, storage, and related technology to run the Application. We may provide the ability to integrate the Services with certain third-party applications that may be utilized at your own option and risk. You understand and agree that we have no liability arising from the use of such third-party vendors, partners, or integrations with third-party applications. 
              </p>
              <p>
                The Application may contain certain links, hyperlinks or imbedded links to third party websites. Such links are provided solely for your convenience and reference only, and the inclusion of such a link does not imply any endorsement by the Company or its affiliates of such website, its contents, the services provided thereunder or its owner. The Company and its affiliates are not responsible for the accuracy or content of the information provided by that website, nor are they liable for any direct or indirect damages, losses, claims or liabilities arising out of your access to, or use of, such website or the services offered thereunder.
              </p>
              <p>
                We make no claims that the Website or any of its content is accessible or appropriate outside of the United States. Access to the Website may not be legal by certain persons or in certain countries. If you access the Website from outside the United States, you do so on your own initiative and are responsible for compliance with local laws.
              </p>
              <p>
                THE APPLICATION, INCLUDING ANY CHANGES, IMPROVEMENTS, AMENDMENTS OR OTHER MODIFICATIONS THERETO, IS PROVIDED ON AN <b>“AS IS, WHERE IS” BASIS AND THE COMPANY HEREBY EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, OR OTHERWISE WITH RESPECT TO SUCH ITEMS OR THE USE THEREOF, INCLUDING ANY WARRANTIES RELATED TO MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. </b>
              </p>
              <p>
                THE COMPANY DOES NOT WARRANT THE COMPLETENESS, ACCURACY, OR USEFULNESS OF ANY INFORMATION PROVIDED TO YOU VIA THE APPLICATION. SUCH INFORMATION IS SUBJECT TO CHANGE WITHOUT PRIOR NOTICE. 
              </p>
              <p>
                THE EVALUATION OF THE ACCURACY, COMPLETENESS OR USEFULNESS OF SUCH ITEMS, THE INFORMATION CONTAINED THEREIN, AND ANY OPINION, ADVICE OR OTHER CONTENT RELATED THERETO THAT IS PROVIDED TO YOU BY THE COMPANY OR ITS AFFILIATES, WHETHER THROUGH THE WEBSITE OR AS PART OF THE SERVICES, IS YOUR RESPONSIBILITY. 
              </p>
              <p>
                YOU HEREBY AGREE THAT THE COMPANY AND ITS AFFILIATES, OFFICERS, EMPLOYEES, BOARD MEMBERS, AND AGENTS ARE NOT, AND WILL NOT BE, LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES OR OTHER INJURY ARISING OUT OF OR IN ANY WAY CONNECTED WITH YOUR USE OR THE INABILITY TO USE THIS WEBSITE AND/OR THE SERVICES OR WITH THE DELAY OR INABILITY TO USE THIS WEBSITE, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS AND SERVICES OBTAINED THROUGH THIS WEBSITE, INCLUDING BUT NOT LIMITED TO RELIANCE BY YOU ON ANY INFORMATION OBTAINED THROUGH THE WEBSITE, OR THAT RESULT FROM MISTAKES, OMISSIONS, INTERRUPTIONS, DELETION OF FILES OR EMAILS, ERRORS, DEFECTS, VIRUSES, WORMS, TROJAN HORSES, TRAP DOORS, BACK DOORS, EASTER EGGS, TIME BOMBS, CANCELBOTS OR OTHER CODE OR COMPUTER PROGRAMMING ROUTINES THAT CONTAIN CONTAMINATING OR DESTRUCTIVE PROPERTIES OR THAT ARE INTENDED TO DAMAGE, DETRIMENTALLY INTERFERE WITH, SURREPTITIOUSLY INTERCEPT OR EXPROPRIATE ANY SYSTEM, DATA OR PERSONAL INFORMATION, DELAYS IN OPERATION OR TRANSMISSION, OR ANY FAILURE OF PERFORMANCE, WHETHER OR NOT RESULTING FROM ACTS OF GOD, COMMUNICATIONS FAILURE, THEFT, DESTRUCTION OR UNAUTHORIZED ACCESS TO THE COMPANY’S RECORDS, PROGRAMS OR SERVICES, OR OTHERWISE ARISING OUT OF THE USE OF THIS WEBSITE, WHETHER RESULTING IN WHOLE OR IN PART, FROM BREACH OF CONTRACT, TORTIOUS BEHAVIOR, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF THE COMPANY AND/OR ITS SUPPLIERS, PROVIDERS, AFFILIATES HAVE BEEN ADVISED OF THE POSSIBILITY OF DAMAGES.
              </p>
              <p>
                The foregoing does not affect any warranties that cannot be excluded or limited under applicable law. 
              </p>
              <p>
                <b>
                  Indemnification
                </b>
              </p>
              <p>
                As a condition of use of the Application, you hereby agree to indemnify and hold harmless, and at the Company’s option, defend the Company and its affiliates from and against any and all liabilities, costs, expenses (including any reasonable attorneys’ fees), and other damages arising out of or related to your use of the Application.  Notwithstanding the foregoing, if you do assume the obligation to defend such claims, you may not settle any such claim against the Company unless Company consents to such settlement, and further provided that the Company will have the right, at its option, to defend itself against any such third-party claim or to participate in the defense thereof by counsel of its own choice.
              </p>
              <p>
                <b>
                  Privacy Policy
                </b>
              </p>
              <p>
                Our Privacy Policy (the “<b>Privacy Policy</b>”), available at https://www.gocobble.com/privacy-policy/, and which is incorporated by reference herein, sets forth the Company’s policy applicable to the information that is collected or developed based upon the information collected, from you by the Company via the Application or otherwise.
              </p>
              <p>
                <b>
                  Changes to the Terms of Use
                </b>
              </p>
              <p>
                We may revise and update these Terms from time to time in our sole discretion. All changes are effective immediately when we post them.  Your continued use of the Website following the posting of revised Terms means that you accept and agree to the changes. You are expected to check this page so you are aware of any changes, as they are binding on you.
              </p>
              <p>
                <b>
                  Governing Law and Jurisdiction
                </b>
              </p>
              <p>
                All matters relating to the Application and these Terms, and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the internal laws of the State of Ohio without giving effect to any choice or conflict of law provision or rule (whether of the State of Ohio or any other jurisdiction).
              </p>
              <p>
                Any legal suit, action, or proceeding arising out of, or related to, these Terms or the Application shall be instituted exclusively in the federal courts of the United States located in the State of Ohio or the courts of the State of Ohio, in either case in the County of Franklin. You hereby waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.
              </p>
              <p>
                <b>
                  Waiver and Severability
                </b>
              </p>
              <p>
                No waiver by the Company of any term or condition set out in these Terms shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of the Company to assert a right or provision under these Terms shall not constitute a waiver of such right or provision.
              </p>
              <p>
                Should any part of these Terms be determined to be illegal, invalid, void or otherwise unenforceable pursuant to applicable law including, without limitation, any warranty disclaimers, liability limitations or arbitration provisions set forth above, then such illegal, invalid, void or otherwise unenforceable provision shall be deemed to be superseded by a validly enforceable provision that most closely matches the intent of the original provision, and the remainder of these Terms shall continue in effect. You hereby irrevocably agree that a printed copy of these Terms (along with evidence of your acceptance hereof, including evidence of your use of the Application while the Terms are in effect), and any notice given hereunder electronically, shall be admissible in any judicial, administrative or arbitration proceedings arising our or otherwise relating to these Terms to the same extent and subject to the same conditions as other business documents and records originally generated, maintained, and executed in printed form. All of the Company’s rights that are not expressly granted herein are hereby reserved.
              </p>
              <p>
                <b>
                  Entire Agreement
                </b>
              </p>
              <p>
                The Terms, our Privacy Policy, and any supplemental terms between you and the Company constitute the sole and entire agreement between you and the Company regarding the Application and supersede all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, regarding the Application. 
              </p>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
}

export default Term;
